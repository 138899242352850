

// This module will attempt to do away with all the insanity of states and variations 
// of the limited login steps. 
// These steps apply to Alloc Req, Accnt Req, Accnt React, dataSurvey
// This is the master state diagram: for local variable: stage
//
//  1   user needs to enter email, first name, last name
//  2   code to user's email has been sent, user needs to enter code
//  3   email code has been typed by user, and verified by backend
//  4   User needs to create security question and answer
//  4.5 User has sent Q & A to backend
//  5   User is challenged with Q and needs to enter A
//  6   All done with this module
//
// Note: for alloc Req: steps 4, 4.5, 5 are not used


var limLoginModule = (function(){

    "use strict";

    var publicStuff;
    var clog = null;
    publicStuff = {
        verifyEmailCode : verifyEmailCode,
        pageLoadInit    : pageLoadInit,
    };

    function pageLoadInit(t, comMod, http){

        // t.state will have the user info

        // this is more complicated than what it seems.
        // this function needs to wait until all the parents, grandparent components
        // finish their asynch initialization and validation

        clog = comMod.ub3consoleLog;
        step1();

        // first wait for parents/grandparents to finish whatever they need to do w backend
        function step1(){
            comMod.doWhenAPICallsDone(step2);
        }
        function step2(){
            // setUser calls whoami
            comMod.setUser(t.state, step3);
        }
        function step3(){

            comMod.objCopyGuts(t.state.user, t.user);

            // already signed in ?
            if (!t.user.signedIn){
                //init from scratch
                t.flags.LLStage = 'A';
                t.flags.emailCodeVerified = false;
                t.flags.okToSubmit = false;
                t.stage = 1;
                t.initDone = true;
                return;
            }
            // user is signed in

            if (t.user.role != 'limited'){
                comMod.signOut(null, t.state, true);
                t.initDone = true;
                return;
            }

            // ok, at this point, we know that there was already a lim login session
            // user probably refreshed the page

            t.flags.emailCodeVerified = t.user.limited_email_verified;

            // find out what kind of limited login
            if (t.user.limited_type == 'REQUEST'){

                t.LLF.email = t.user.email;
                t.LLF.firstName = t.user.firstName;
                t.LLF.lastName = t.user.lastName;

                // set the stage level
                if (!t.user.limited_email_verified){
                    t.stage = 1;
                    t.validateLLNow(t.LLF);
                    t.initDone = true;
                    return;
                }
                
                if (!t.user.limited_qa_verified){
                    t.stage = 4;
                    t.flags.LLStage = 'C';  // so that validation knows which fields to validate
                    t.flags.okToSaveQA = false;
                    t.validateLLNow(t.LLF);
                    t.initDone = true;
                    return;
                }
                // at this point, the user has made it past the sec Q & A
                t.flags.level = t.stage = 6;
                t.initDone = true;
                if (t.after) t.after(t.LLF);
            }
            if (t.user.limited_type == 'REACTIVATE'){   // account reactivate or data survey
                // almost the same as account request,  but user is challenged with a Question
                // and user needs to type in the Answer
                t.LLF.email = t.user.email;
                t.LLF.firstName = t.user.firstName;
                t.LLF.lastName = t.user.lastName;
                t.validateLLNow(t.LLF);

                // set the stage level
                if (!t.user.limited_email_verified){
                    t.stage = 1;
                    t.validateLLNow(t.LLF);
                    t.initDone = true;
                    return;
                }
                
                if (!t.user.limited_qa_verified){
                    t.stage = 5;
                    t.validateLLNow(t.LLF);
                    getSecurityQuestion(http, function(resp){
                        if (resp.success){
                            t.user.securityQuestion = resp.question;
                        }
                    });
                    t.initDone = true;
                    return;
                }
                // at this point, the user has made it past the sec Q & A
                t.flags.level = t.stage = 6;
                t.initDone = true;
                if (t.after) t.after(t.LLF);
            }
            if (t.user.limited_type == 'DDALLOC_REQUEST'){
                // no initialization needed for allocation request page. It has its own validations.
                t.initDone = true;
            }
        }
    }


    // new function:
    // It will attempt to NOT do implicitly setting of
    // results in obscure places.
    // Any results (or side effects) will now be returned in a callback
    // Inputs have to be sent in as explicit parameters.
    // Outputs have to be returned with explicit names in the callback
    // callback is not optional
    //
    function verifyEmailCode(code, acceptedLength, action, http, cookies, callback){
        var output = {
            level: null,
            stage: null,
            LLStage: null,
            readError: null,
            askForCode: null,
            askForCodeError : null,
            confirmingCode : null,
            emailCodeVerified : null,
            securityQuestion : null,
            cleanCode: null,
        }
        var ccode = code ? code.trim() : code ;
        output.emailCodeVerified = false;

        var p = {emailCode: ccode};
        if (ccode && (ccode.length > acceptedLength)){
            ccode = ccode.substring(0, acceptedLength);
        }
        output.cleanCode = ccode;
        if (ccode && (acceptedLength != ccode.length)){
            output.askForCodeError = 'Please copy & paste the exact code from the email that we sent you.' ;
            callback(output);
            return;
        }
        output.confirmingCode = true;
        output.askForCodeError = null;
        output.readError = null;


        http.post("/limited/verifyEmailCode", p)
            .success(function(resp){
                var done = true;
                if (resp.success){
                    output.emailCodeVerified = true;
                    output.askForCode = false;
                    // determine which fields are required
                    if (action=='requestEnterEmailCodeEnterQA')  {
                                output.LLStage = 'C';
                                output.stage = 4;
                    }
                    if (action=='requestEnterAnswer'){
                                output.LLStage = 'D';
                                output.stage = 5;
                    }
                    if (action=='requestEnterEmailCode'){
                                // this one cannot happen if email code 
                                // if verified successfully
                                output.LLStage = 'D';
                                output.stage = 2;
                    }
                    if (action=='reactivateEnterAnswer'){
                                output.LLStage = 'D';
                                output.stage = 5;
                    }
                    if (action=='reactivateEnterEmailCode'){
                                output.LLStage = 'D';
                                output.stage = 2;
                    }      
                    output.level = output.stage;

                    // determine if we need to challenge user for the answer
                    if (action && action.endsWith('EnterAnswer') ||
                        (action=='reactivateEnterEmailCode') ||
                        (action=='requestEnterEmailCode'))
                    {
                        //clog(86, 'confused');
                        done = false;
                        http.get('/limited/securityQuestion')
                            .success(function(resp2){
                                if (resp2.success){
                                    output.securityQuestion = resp2.question;
                                    output.stage = output.level = 5;
                                }else{
                                    output.readError = resp2.error;
                                }
                                callback(output);
                            });
                    }
                }else{
                    var friendlyMessage = "Limited Login session could not be created, possible network failure, or db down error: Network error, please try again.";
                    output.submitError     = friendlyMessage + ' ' + resp.error.message ;
                    output.askForCodeError = output.submitError;
                }
                output.confirmingCode = false;
                if (done){
                    callback(output);
                }
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "EMAIL ACCESS CODE" again.'
                    }
                    output.submitError = msg.data.detail;
                    output.askForCodeError = msg.data.detail;
                }
                callback(output);
            });
    }

    function getSecurityQuestion(http, cb){
        http.get('/limited/securityQuestion').success(function(resp2){
            cb(resp2);
        });
    }

    // ----------- public stuff gets returned -----------
    return publicStuff;

})();

module.exports = {limLoginModule} ;

