<!-- Discretionary Allocation Request -->
<div>
    <div *ngIf="flags.hasSession === false" id="notSignedInYet" class="ml15">
        <div class="reversed-white">
            <h1 id="allocHeading">Discretionary Allocation Request (New & Renewal)</h1>
            <div id="ddAlloc-headp">
                <div class="alcfAcStatus">Based on your ALCF account status, please select from the following to request an allocation:</div>
                <div class="allocSection">
                    <h4>Active ALCF account</h4>
                    <div class="accSection">
                        <a (click)="goHome()" class="grnLnk">Login</a> and request an allocation
                    </div>
                </div>
            </div>
        </div>
        <div id="allocReqEmailVerf" class="allocation-request-email-page">
            <h4>No active ALCF account</h4>
            <div class="noActAc">
                <limited-login
                    [(flags)]="flags"
                    [(user)]="user"
                    [after]="afterLimitedLogin">
                </limited-login>
            </div>
        </div>
    </div>

    <!-- DD Allocation Request Form -->
    <div *ngIf="flags.hasSession" id="signedIn">
        <div class="reversed-white" id="allocReqMsg">
          <h1>Request a Discretionary Allocation</h1>
          <p>Request an allocation on ALCF compute and storage resources. Awards are made year round to industry, academia, laboratories, and others.</p>
        </div>
        <!-- Alert Section -->
        <div *ngIf="authAlerts.length > 0">
          <div class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index">
            <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
              {{ alert.msg }}
            </ngb-alert>
          </div>
        </div>
        <!-- Allocation Request Form -->
        <div *ngIf="flags.displayARForm" class="alloc-form">
          <div class="allocation-request-page">
            <form class="reqAllocForm" id="webform-client-form">
                <div class="allocHeader">
                    Allocation Request Information
                </div>
                <div>
                    <div class="allocFormField" id="webform-component-new-renewal-project">
                        <label for="edit-submitted-new-renewal-project">
                            Is this a new project or renewal project?
                        </label>
                        <div id="edit-submitted-new-renewal-project" class="form-radios">
                            <div class="single-select">
                                <input  type="radio"
                                        id="edit-submitted-new-renewal-project-yes"
                                        [(ngModel)]="form.allocRequest.new_project"
                                        ngDefaultControl
                                        name="submitted[new_renewal_project]"
                                        (change)="validateAllocReq();setReqSpfcChanges();"
                                        [value]=true
                                        class="form-radio" />
                                <label class="option" for="edit-submitted-new-renewal-project-yes">New Project</label>
                            </div>
                            <div class="single-select">
                                <input  type="radio"
                                        id="edit-submitted-new-renewal-project-no"
                                        [(ngModel)]="form.allocRequest.new_project"
                                        ngDefaultControl
                                        name="submitted[new_renewal_project]"
                                        (change)="validateAllocReq(); setReqSpfcChanges();"
                                        [value]=false
                                        class="form-radio" />
                                <label class="option" for="edit-submitted-new-renewal-project-no">Renewal Project</label>
                            </div>
                        </div>
                        <div class="warning">
                          <warning fid="new_project"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-resources">
                        <label for="edit-submitted-resources">
                            Which resource(s) are you requesting?
                        </label>
                        <div class="multi-select" *ngFor="let ar of lists.allocResources; let arIndex = index">
                            <!-- Checkbox to select or unselect resources -->
                            <input
                                id="edit-submitted-resources-{{ar.name}}"
                                [(ngModel)]="ar.selected"
                                [ngModelOptions]="{standalone: true}"
                                ngDefaultControl
                                name="submitted[resources]"
                                value="ar.selected"
                                type="checkbox"
                                [disabled]="ar.disable"
                                (change)="getAllocResources(ar);validateAllocReq();getSubResoDurWarning(ar.name)">
                            <label class="option" for="edit-submitted-resources-{{ar.name}}">
                                <span class="resourceName">{{ar.dispName}}</span>
                                <span *ngIf="ar.label">({{ar.label}})</span>
                                <span *ngIf="ar.notification" class="notifn">({{ar.notification}})</span>
                            </label>
                            <div *ngIf="ar.desc && (!ar.subResources || ar.subResources.length === 0)" class="resoDesc descText" >
                                <span *ngIf="ar.webLink" id="{{ar.name}}-webLink"><a href="{{ar.webLink}}" target="_blank">{{ar.name}}</a></span>
                                <span *ngIf="!ar.webLink">{{ar.name}}</span>
                                {{ar.desc}}
                            </div>
                            <div *ngIf="ar.selected" class="resInfo">
                                <div *ngIf="ar.type === 'compute'">
                                    <div *ngIf="ar.subResources && ar.subResources.length > 0" class="allocFormField" id="webform-component-requested-allocation-{{ar.name}}">
                                        <label *ngIf="ar.subResouce_notes" id="compAllocLbl" for="edit-submitted-requested-allocation-{{ar.name}}">
                                            {{ar.subResouce_notes}}
                                        </label>
                                        <div *ngIf="flags.subResoLength[ar.name] === 0 && ar.subResources.length > 1" class="smallText redText">
                                            Select at least one checkbox
                                        </div>
                                        <div class="compAlloc" *ngFor="let res of ar.subResources; let resIndex = index">
                                            <!-- Checkbox to select or unselect sub-resources -->
                                            <input id="checkbox-requested-allocation-{{res.name}}"
                                                   [(ngModel)]="res.selected"
                                                   [ngModelOptions]="{standalone: true}"
                                                   ngDefaultControl
                                                   name="submitted[sub_resources]"
                                                   value="res.selected"
                                                   type="checkbox"
                                                   [disabled]="res.disable"
                                                   (change)="verifyAllocVal(ar.name, res.name)">
                                            <span class="resourceName">{{res.name}}</span>
                                            <span *ngIf="res.label">({{res.label}})</span>
                                            <span class="helpLabel" (click)="reqSubAllocHelp[resIndex]=!reqSubAllocHelp[resIndex]"  *ngIf="ar.category !== 'testbeds'">
                                              <img height="16px" src="/frontend/assets/info-circle-solid.svg">
                                            </span>
                                            <div *ngIf="res.desc" class="subResoDesc descText">
                                                <span id="{{res.name}}-webLink" *ngIf="res.webLink"><a href="{{res.webLink}}" target="_blank">{{res.nameToLink}}</a></span>
                                                <span *ngIf="!res.webLink">{{res.name}}</span>
                                                {{res.desc}}
                                            </div>
                                            <div *ngIf="ar.category !== 'testbeds'">
                                                <input  type="text"
                                                        id="edit-submitted-requested-allocation-{{res.name}}"
                                                        [(ngModel)]="ar.allocation_amount[res.name]"
                                                        [ngModelOptions]="{standalone: true}"
                                                        ngDefaultControl
                                                        name="submitted[requested_allocation]"
                                                        (keyup)="convertToLocaleString(res.name, 'allocation_amount', 'int', res.parentResource)"
                                                        [disabled]="!res.selected" />
                                                <div *ngIf="res.selected">
                                                    <div *ngIf="res.name === ar.subResources[0].name" class="warning">
                                                        <warning fid="allocation_amount_{{res.name}}"></warning>
                                                    </div>
                                                </div>
                                                <p class="descText m10" *ngIf="reqSubAllocHelp[resIndex]">
                                                    {{res.helpText}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="(!ar.subResources || ar.subResources.length === 0)" class="allocFormField" id="webform-component-requested-allocation-{{ar.name}}">
                                        <label for="edit-submitted-requested-allocation-{{ar.name}}">
                                            How much compute allocation do you need on {{ar.name}}? (in {{ar.unit}})
                                            <span class="helpLabel" (click)="reqAllocHelp[arIndex]=!reqAllocHelp[arIndex]" *ngIf="ar.helpText">
                                              <img height="16px" src="/frontend/assets/info-circle-solid.svg">
                                            </span>
                                        </label>
                                        <div>
                                            <input  type="text"
                                                    id="edit-submitted-requested-allocation-{{ar.name}}"
                                                    [(ngModel)]="ar.allocation_amount[ar.name]"
                                                    [ngModelOptions]="{standalone: true}"
                                                    ngDefaultControl
                                                    name="submitted[requested_allocation]"
                                                    (keyup)="convertToLocaleString(ar.name,'allocation_amount', 'int', null);"/>
                                            <div class="warning">
                                              <warning fid="allocation_amount_{{ar.name}}"></warning>
                                            </div>
                                            <p class="descText m10" *ngIf="reqAllocHelp[arIndex]">
                                                {{ar.helpText}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="allocFormField" *ngIf="(ar.category === 'computeWStorage' && !flags.newProjwStorage)" class="ml10">
                                    <span id="edit-submitted-requested-email-{{ar.name}}">Email <a href="mailto:support@alcf.anl.gov">support&#64;alcf.anl.gov</a> for space quota renewals.</span>
                                </div>
                                <div *ngIf="(ar.canEditStorage && flags.newProjwStorage)" class="ml10 allocFormField" style="margin-top:-20px">
                                    <span class="descText" id="edit-submitted-not-requested-storage-{{ar.name}}">If you will be using existing storage allocations, please deselect the Eagle storage checkbox below.</span>
                                </div>
                                <div class="allocFormField" *ngIf="(ar.type === 'storage')">
                                    <label for="edit-submitted-requested-quota-{{ar.name}}">
                                    How much space allocation do you need on {{ar.name}}? ({{ar.unit}})
                                    </label>
                                    <div>
                                        <input  type="text"
                                                id="edit-submitted-requested-quota-{{ar.name}}"
                                                [(ngModel)]="ar.quota_amount[ar.name]"
                                                [ngModelOptions]="{standalone: true}"
                                                ngDefaultControl
                                                name="submitted[requested_quota]"
                                                (keyup)="convertToLocaleString(ar.name,'quota_amount','float', null);"/>
                                    </div>
                                    <div class="warning inputwarning has-error" *ngIf="ar.quota_amount[ar.name] <= 0" id="requiredField_quota_amount_{{ar.name}}">
                                        <span>Required Field</span>
                                    </div>
                                </div>
                                <div *ngIf="ar.type !== 'storage'" class="allocFormField" id="webform-component-requested-duration-{{ar.name}}">
                                    <label for="edit-submitted-requested-duration-{{ar.name}}">
                                        How long do you need the allocation for?
                                    </label>
                                    <div id="edit-submitted-requested-duration-{{ar.name}}" class="form-radios" *ngFor="let dur of ar.allocPeriod; let durIndex = index">
                                        <div class="single-select">
                                            <input  type="radio"
                                                    id="edit-submitted-requested-duration-{{ar.name}}-{{dur.replace(' ','_')}}"
                                                    [(ngModel)]="ar.duration[ar.name]"
                                                    ngDefaultControl
                                                    name="submitted[requested_duration]{{arIndex+1}}"
                                                    (change)="validateAllocReq();getSubResoDurWarning(ar.name)"
                                                    value="{{dur}}"
                                                    class="form-radio" />
                                            <label class="option" for="edit-submitted-requested-duration-{{ar.name}}-{{dur.replace(' ','_')}}">{{dur}}</label>
                                        </div>
                                    </div>
                                    <div class="warning" *ngIf="!durReqd[ar.name]">
                                      <warning fid="duration_{{ar.name}}"></warning>
                                    </div>
                                    <div
                                        class="warning inputwarning has-error"
                                        *ngIf="durReqd[ar.name]" id="requiredField">
                                        <span>Required Field</span>
                                    </div>
                                </div>
                                <div *ngIf="ar.type === 'storage'" class="descText defDur">
                                  <span *ngIf="ar.category === 'combined' || ar.category === 'combined_with_Aurora'">
                                    Default Allocation Period: Same duration as your compute allocation request above.
                                  </span>
                                  <!-- The allocation is defaulted to 2 years for Eagle -->
                                  <span *ngIf="ar.category === 'single'">
                                    Default Allocation Period: {{ar.duration[ar.name]}}
                                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="warning">
                          <warning fid="resource_name"></warning>
                        </div>
                    </div>
                </div>
          
                <div>
                    <!-- Project Information -->
                    <div class="allocHeader">
                        Project Information
                    </div>
                    <div class="allocFormField" id="webform-component-principal-investigator">
                        <label for="edit-submitted-principal-investigator">
                            PI Name
                        </label>
                        <div>
                            <input type="text"
                            id="edit-submitted-principal-investigator"
                            [(ngModel)]="form.allocRequest.principal_investigator_name"
                            ngDefaultControl
                            name="submitted[principal_investigator]"
                            (keyup)="validateAllocReq()" />
                        </div>
                        <div class="warning">
                          <warning fid="principal_investigator_name"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-pi-email">
                        <label for="edit-submitted-pi-email">
                            PI Email
                        </label>
                        <div>
                            <input  class="email form-email"
                                    type="email"
                                    id="edit-submitted-pi-email"
                                    [(ngModel)]="form.allocRequest.principal_investigator_email"
                                    ngDefaultControl
                                    name="submitted[pi_email]"
                                    (keyup)="validateAllocReq()" />
                        </div>
                        <div class="warning">
                          <warning fid="principal_investigator_email"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-institution">
                        <label for="edit-submitted-institution">
                            PI Institution
                        </label>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-institution"
                                    [(ngModel)]="form.allocRequest.project_institution"
                                    ngDefaultControl
                                    name="submitted[institution]"
                                    placeholder="Please type at least two characters"
                                    (ngModelChange)="validateAllocReq()"
                                    (keyup)="validateAllocReq()"
                                    class="form-control"
                                    maxlength="50"
                                    [ngbTypeahead]="searchKey"/>
                        </div>
                        <div class="warning">
                          <warning fid="project_institution"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-ind-related-project">
                        <label for="edit-submitted-ind-related-project">
                            Industry-related project
                            <div class="descText">
                                This project has team members from industry and/or the results are intended for industry use.
                            </div>
                        </label>
                        <div id="edit-submitted-ind-related-project" class="form-radios">
                            <span class="single-select industry">
                                <input  type="radio"
                                        id="edit-submitted-ind-related-project-yes"
                                        [(ngModel)]="form.allocRequest.industry_related"
                                        ngDefaultControl
                                        name="submitted[ind-related_project]"
                                        (ngModelChange)="validateAllocReq()"
                                        [value]=true
                                        class="form-radio" />
                                <label class="option" for="edit-submitted-ind-related-project-yes">Yes</label>
                                <input  type="radio"
                                        id="edit-submitted-ind-related-project-no"
                                        [(ngModel)]="form.allocRequest.industry_related"
                                        ngDefaultControl
                                        name="submitted[ind-related_project]"
                                        (ngModelChange)="validateAllocReq()"
                                        [value]=false
                                        class="form-radio" />
                                <label class="option" for="edit-submitted-ind-related-project-no">No</label>
                            </span>
                        </div>
                        <div class="warning" *ngIf="!(form.allocRequest.industry_related == true || form.allocRequest.industry_related == false)">
                            <font color="#c23a09">Required Field</font>
                        </div>
                    </div>

                    <div class="allocFormField" id="webform-component-project-title">
                        <label for="edit-submitted-project-title">
                            Project Title
                            <div class="descText">
                                This should be the formal name of your project.  Ex: DNS Simulations of High-speed Combustion and Detonations
                            </div>
                        </label>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-project-title"
                                    [(ngModel)]="form.allocRequest.project_formal_title"
                                    ngDefaultControl
                                    name="submitted[project_title]"
                                    (keyup)="validateAllocReq()" />
                            <div class="warning">
                              <warning fid="project_formal_title"></warning>
                            </div>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-project-name">
                        <label for="edit-submitted-project-name">
                            Project Short Name (Internal Name)
                            <div class="descText" id="nameValidate">
                                This will be the name of the project that is used to track your usage of ALCF resources. It can only be made up of alphanumeric characters (A-Z, a-z, 0-9), dashes (-), and underscores (_). It can not have spaces in it or be longer than 20 characters.
                            </div>
                        </label>
                        <div>
                            <!-- Project Short Name shouldn't be longer than 20 characters -->
                            <input  type="text"
                                    id="edit-submitted-project-name"
                                    [(ngModel)]="form.allocRequest.project_name"
                                    ngDefaultControl
                                    name="submitted[project_name]"
                                    maxlength=20
                                    (keyup)="validateAllocReq(); validateProjName();" />
                            <div class="warning">
                              <warning fid="project_name"></warning>
                            </div>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-project-url">
                        <label for="edit-submitted-project-url">
                            Project website
                        </label>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-project-url"
                                    [(ngModel)]="form.allocRequest.project_website"
                                    ngDefaultControl
                                    name="submitted[project_url]"
                                    (keyup)="validateAllocReq()" />
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-scientific-field">
                        <label for="edit-submitted-scientific-field">
                            Scientific Field
                        </label>
                        <div>
                            <select id="edit-submitted-scientific-field"
                                    [(ngModel)]="form.allocRequest.scientific_field"
                                    ngDefaultControl
                                    name="submitted[scientific_field]"
                                    (ngModelChange)="validateAllocReq()"
                                    class="form-control">
                                    <option value="" [disabled]="true">Select</option>
                                    <option value={{sf}} *ngFor="let sf of lists.scientific_field">{{sf}}</option>
                            </select>
                        </div>
                        <div class="warning">
                          <warning fid="scientific_field"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-funding-agencies">
                        <label for="edit-submitted-funding-agencies">
                            Project funding agencies (list all separated by commas)
                        </label>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-funding-agencies"
                                    [(ngModel)]="form.allocRequest.project_funding_agency"
                                    ngDefaultControl
                                    name="submitted[funding_agencies]"
                                    (keyup)="validateAllocReq()" />
                        </div>
                        <div class="warning">
                          <warning fid="project_funding_agency"></warning>
                        </div>
                    </div>
                    <div *ngIf="!flags.fsOnlyAlloc" class="allocFormField" id="webform-component-application-code-name">
                        <label for="edit-submitted-application-code-name">
                            Which application code(s) does your project use?
                        </label>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-application-code-name"
                                    [(ngModel)]="form.allocRequest.application_name"
                                    ngDefaultControl
                                    name="submitted[application_code_name]"
                                    (keyup)="validateAllocReq()" />
                        </div>
                        <div class="warning">
                          <warning fid="application_name"></warning>
                      </div>
                    </div>
                    <div class="allocHeader">
                        Project Request Justification
                    </div>
                    <div class="ml10">Please provide the reasons below on why you need to use our systems.</div>
                    <br>
                    <div *ngIf="!flags.fsOnlyAlloc" class="allocFormField mt10" id="webform-component-other-systems">
                        <label for="edit-submitted-other-systems">
                            Which other HPC systems have run this code?
                        </label>
                        <div class="smallText">NCSA-BlueWaters, NERSC-Cori, OLCF-Summit, etc.</div>
                        <div>
                            <input  type="text"
                                    id="edit-submitted-other-systems"
                                    [(ngModel)]="form.allocRequest.other_systems"
                                    ngDefaultControl
                                    name="submitted[other_systems]"
                                    (keyup)="validateAllocReq()" />
                        </div>
                        <div class="warning">
                          <warning fid="other_systems"></warning>
                        </div>
                    </div>
                    <div class="allocFormField" id="webform-component-preparation">
                        <label for="edit-submitted-preparation">
                            I will use this allocation to prep for:
                        </label>
                        <div>
                            <select id="edit-submitted-preparation"
                                    [(ngModel)]="form.allocRequest.preparation_for"
                                    ngDefaultControl
                                    name="submitted[preparation]"
                                    (ngModelChange)="validateAllocReq()"
                                    class="form-control">
                                    <option value="" [disabled]="true"> Select </option>
                                    <option value={{ap}} *ngFor="let ap of lists.preparation_for">{{ap}}</option>
                            </select>
                        </div>
                        <div class="warning">
                          <warning fid="preparation_for"></warning>
                        </div>
                    </div>
                    <br>
                    <div>
                        <div class="ml10">Guidance for writing your Project and Justification Summary</div>
                        <table class="guideDetails smallText">
                            <tr><td colspan="2"><b>Details should include:</b></td></tr>
                            <tr><td>- Expected results and timeline for project (e.g. submit proposal, code development, publication)</td></tr>
                            <tr><td>- Available details on proposal plans (allocation program targeted, deadline for submission, PI name, expected title, etc.)</td></tr>
                            <tr><td>- Justification for each of the requested ALCF resources and how they are important to project goals</td></tr>
                            <tr><td>- Longer term goals for project (do you expect to require more time after allocation has ended?)</td></tr>
                            <tr><td>- Codes being used</td></tr>
                            <tr><td>- Development work planned</td></tr>
                            <tr><td>- Experience with code on requested machine (or similar architecture) sizes of calculations planned for ALCF</td></tr>
                        </table>
                        <br>
                        <table class="guideDetails smallText" id="aITestbedGuidelines">
                            <tr><td colspan="2"><b>If an AI Testbed system is selected, in addition to the summary above, the proposal needs to include:</b></td></tr>
                            <tr><td>- Details of the model architecture</td></tr>
                            <tr><td>- Deep learning framework used to date, and any ML/DL libraries needed</td></tr>
                            <tr><td>- Dataset information and storage requirements</td></tr>
                            <tr><td>- Scaling approaches being targeted, if any.</td></tr>
                        </table>
                        <br>
                        <table class="guideDetails smallText">
                            <tr><td colspan="2"><b>Renewal requests should also include:</b></td></tr>
                            <tr><td>-</td><td>What you have accomplished with your original allocation - please include a brief description of any publications or presentations that were (or will be) generated in part because of this Discretionary allocation.</td></tr>
                            <tr><td>-</td><td>What you will do with the extra time.</td></tr>
                        </table>
                    </div>
                    <br>
                    <div class="allocFormField" id="webform-component-description">
                        <label for="edit-submitted-description">
                            Project and Justification Summary
                        </label>
                        <p class="smallText">
                            Justification for requesting each resource MUST be included in this field. See above for guidance.
                        </p>
                        <div class="form-textarea-wrapper resizable">
                            <textarea   id="edit-submitted-description"
                                        [(ngModel)]="form.allocRequest.description"
                                        ngDefaultControl
                                        name="submitted[description]"
                                        (change)="validateAllocReq()"
                                        (keyup)="validateAllocReq()"
                                        cols="60"
                                        rows="12"
                                        class="form-textarea">
                            </textarea>
                            <div class="warning">
                              <warning fid="description"></warning>
                            </div>
                        </div>
                    </div>
                    <div class="allocFormField btmCheckbox" id="alcfDataPolicy">
                        <div id="alcfDataPolicyLabel" class="mb10">
                            Data Policy Acknowledgement
                        </div>
                        <div class="multi-select" id="alcfDataPolicyCheck">
                            <input
                                  id="alcfDataPolicyCheckbox"
                                  [(ngModel)]="form.allocRequest.dataPolicy"
                                  ngDefaultControl
                                  name="submitted[dataPolicy]"
                                  type="checkbox"
                                  (change)="validateAllocReq();">
                              <label class="option" for="alcfDataPolicyCheckbox">
                                  I agree that I have read and will abide by the <a href="{{DDAllocationDataPolicyURL}}" target="_blank"> ALCF data policy</a>.
                              </label>
                        </div>
                    </div>
                    <div class="allocFormField btmCheckbox" id="sensitiveInfo">
                      <div class="multi-select" id="sensitiveInfoCheck">
                          <input
                                  id="sensitiveInfoCheckbox"
                                  [(ngModel)]="form.allocRequest.sensitiveProjInfo"
                                  ngDefaultControl
                                  name="submitted[sensitiveInfo]"
                                  type="checkbox"
                                  (change)="validateAllocReq();">
                              <label class="option" for="sensitiveInfoCheckbox">
                                I confirm that my project does not use or generate HIPAA, ITAR, or Export Control data.
                              </label>
                      </div>
                    </div>
                    <div id="submitOrCancel">
                        <button style="width:150px"
                                id="allocReqSubmitBtn"
                                class="btn btn-primary btn-md submitBtn"
                                [(ngModel)]="form.allocRequest.submit"
                                ngDefaultControl
                                name="submitted[alloc_req_form]"
                                (click)="submitAllocRequest()"
                                [disabled]="!flags.okToSubmit"
                                >
                            Submit
                        </button>
                        <a (click)="cancelAllocRequest()" id="allocReqCancelBtn">
                            Cancel request
                        </a>
                    </div>
                </div>
            </form>
            <div *ngIf="flags.showMissingData" class="reqAllocForm missing">
                Missing: {{flags.missingData}}
            </div>
          </div>
        </div>
    </div>
</div>