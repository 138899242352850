import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { commonModule  } from 'commonModule';
import { SessionTimeoutService } from './session-timeout.service';
import { environment } from '@environments/environment';

let http = null;
let t = null;   // pointer to the instatiated object of class Ub3httpService
let clog = commonModule.ub3consoleLog;

@Injectable({
    providedIn: 'root'
})
export class Ub3httpService {

    state = {};

    constructor(
                private h: HttpClient, 
                private cookies: CookieService,
                private sessionTimeout: SessionTimeoutService
    ){
        t = this;
        http = h;   // the original one
        t.state = commonModule.getSharedObject('app','state');
    };
    defaults = {headers: {get: {}, put: {}, post:{}}};  // used in old angular 1 .js files

 /* 
    get(path, params?){
        var r:any, ret;
        commonModule.changeCallsPending('GET' + ' ' + path, t.state, 1);
        r = http.get(path, params);
        ret = new myReqRetObj(r, 'GET', path, params);
        return ret;
    }
    post(path, params, h?){
        var r:any, ret, opts;
        commonModule.changeCallsPending('POST' + ' ' + path, t.state, 1);
        opts = t.getCSRF();
        r = http.post(path, params, opts);
        ret = new myReqRetObj(r, 'POST', path, params);
        return ret;
    }
    put(path, params, h?){
        var r:any, ret, opts;
        
        if (path == '/limited/delSaveForLater'){
            http.put(path).subscribe(
                function(a){clog(51, {a:a});},
                function(b){clog(52, {b:b});}
            );
            return;
        }
        
        commonModule.changeCallsPending('PUT' + ' ' + path, t.state, 1);
        opts = t.getCSRF();
        ret = new myReqRetObj({}, 'PUT', path, params);
        r = http.put(path, params, opts);
        ret = new myReqRetObj(r, 'PUT', path, params);
        return ret;
    }
    getCSRF(){
        var tokenName, csrf, opts;
        tokenName  = (t.state.domain ==='cels') ? 'cels_csrftoken' : 'portal_csrftoken';
        csrf = t.cookies.get(tokenName);
        opts = {
                headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        return opts;
    }
    */

    
    get (path, params) { return doTheCall('GET' , path, params);};
    post(path, params) { return doTheCall('POST', path, params);};
    put (path, params) { return doTheCall('PUT' , path, params);};
    
}

function myReqRetObj(origReqRetObj, method, path, params){

        var tm = this;
        tm.origOnGood = null;
        tm.origOnBad = null;
        tm.embedData = false;
        tm.fin = null;

        tm.subscribe = function(cgood, cbad){   
            // save the original callback functions
            tm.origOnGood = cgood;
            tm.origOnBad  = cbad;
    
            // call mine first, mine will call the originals
            if (cgood && cbad ) { return origReqRetObj.subscribe(tm.myOnGood, tm.myOnBad);}
            if (cgood && !cbad) { return origReqRetObj.subscribe(tm.myOnGood);}
        }

        tm.then= function(f){       // angular 1.7.2 uses .then - backward compatibility
            tm.embedData = true;
            tm.origOnGood = f;
            var rc = origReqRetObj.subscribe(tm.myOnGood, tm.myOnBad);
            // modify return object
            rc.catch = function(f2){ 
                tm.origOnBad = f2;
                return {finally: function(f3){
                            tm.fin = f3;
                       }};
            };
            return rc;
        };
        tm.success = function(f){   // angular 1.5.7 uses .success - backward compatibility
            tm.origOnGood = f;
            var rc = origReqRetObj.subscribe(tm.myOnGood, tm.myOnBad);
            rc.error = function(ef){
                            tm.origOnBad = ef;
                       }
            return rc;
        };

        tm.myOnGood = function(resp){
            // at this point we got data from the backend

            var infoString =  method + ' ' + path + ' ' + JSON.stringify(params) ;
            t.sessionTimeout.resetTimer(path, infoString, 'API');
    
            var result = tm.embedData ? {data: resp} : resp;
            if (tm.origOnGood) tm.origOnGood(result);
            if (tm.fin) tm.fin();
            commonModule.changeCallsPending('back from ' + method + ' ' + path, t.state, -1);
        }
        tm.myOnBad = function(error){
            // at this point we got data from the backend, with error info

            var eres = {success: false, error: error};
            var result = tm.embedData ? {data: eres} : eres;
            //clog(128, {error:error, status:error.status});
            if (tm.origOnBad ) {
                tm.origOnBad(result);
            }else{
                //call the good one
                tm.origOnGood(result);
            }
            if (tm.fin) tm.fin();
            commonModule.changeCallsPending('failure, back from ' + method + ' ' + path, t.state, -1);

            // review status codes here: https://umbraco.com/knowledge-base/http-status-codes/
            // with George
            var ok = (error.status < 400);
            if (!ok) window.location.href = '/';
        }
}


function doTheCall(m, path, params){
    if (t.state.domain != 'cels') {
        path = pathPrepend(path, environment.apiUrl);
    }
    commonModule.changeCallsPending(m + ' ' + path, t.state, 1);
    var callback = null;
    var erFunc = null;
    var pipeParam = null;
    var errorCallback = null;
    var fin = null;
    var embedData = false;
    var csrf = null;
    var subsRetObj = null;
    var csrftoken  = (t.state.domain ==='cels') ? 'cels_csrftoken' : 'portal_csrftoken';

    if (m == 'GET' ) {
        // dont need csrftoken on GET
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.get (path, params, opts).subscribe(onGood, onBad);
    }
    if (m == 'POST') {
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.post(path, params, opts).subscribe(onGood, onBad);
    }
    if (m == 'PUT' ) {
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.put (path, params, opts).subscribe(onGood, onBad);
    }

    function onGood(resp){
        var infoString =  m + ' ' + path + ' ' + JSON.stringify(params) ;
        t.sessionTimeout.resetTimer(path, infoString, 'API');

        var result = embedData ? {data: resp} : resp;
        if (pipeParam) pipeParam(result);
        if (callback) callback(result);
        if (fin) fin();
        commonModule.changeCallsPending('back from ' + m + ' ' + path, t.state, -1);
    }
    function onBad(error){
        var eres = {success: false, error: error};
        var result = embedData ? {data: eres} : eres;
        // with this next line,  no need to worry about .then or .catch or a 2nd parameter to subscribe
        if (pipeParam) pipeParam(result);
        if (erFunc) {
            erFunc(result);
        }else{
            if (callback) callback(result);
        }
        if (pipeParam) pipeParam(result);
        if (fin) fin();
        commonModule.changeCallsPending('failure, back from ' + m + ' ' + path, t.state, -1);

        var ok = (error.status < 400);
        if (!ok) window.location.href = '/';
    }

    return {
        subscribe: function(f){   // angular 2+ - uses .subscribe
            callback = f;
            return subsRetObj;
        },
        pipe: function(f){        // angular 2+ - uses .pipe optionally
            pipeParam = f;        // param for pipe is always a function
            return {
                subscribe: function(f2){
                    callback = f2;
                    return subsRetObj;
                }
            }
        },
        success: function(f){             // angular 1.5.7 uses .success - backward compatibility
            callback = f;
            return {error: function(ef){
                                erFunc = ef;
                            }
                   } ;
        },
        then: function(f){                // angular 1.7.2 uses .then - backward compatibility
            embedData = true;
            callback = f;
            return {
                    catch: function(){
                        return {finally: setFinally};
                    },
                    finally: setFinally
            };
        }
    };

    function setFinally(f){
        fin = f;
    }
}


function pathPrepend(str, prefix){
    var result, temp ;
    temp = str;
    if (temp[0] != '/') temp = '/' + temp;
    result = (prefix == '') ? temp : 
           ((temp.indexOf(prefix) == 0) ? temp : prefix + temp);
    
    return result;
}



