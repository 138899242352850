import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {CommonService, Ub3httpService} from '@common/services';
import {accountReactModule} from './accountReactModule';
import {editRecordModule}       from '../../../../../../../../frontend/editRecordModule';
import {validationModule}       from '../../../../../../../../frontend/validationModule';
import {CookieService} from 'ngx-cookie-service';
import { SweetAlert } from '../../../shared/services/sweetalert.service';

var t: any = null;
var scope = null;
var clog = null;
var http = null;

@Component({
  selector: 'account-reactivate',
  templateUrl: './account-reactivate.component.html',
  styleUrls: ['./account-reactivate.component.scss']
})
export class AccountReactivateComponent implements OnInit {

    state: any = null;
    userName: string = null;
    flags = {err1:null, answerVerified: false, reactivateSubmitted: false,
        okToSubmit: false
    };
    publicPage(b) { };
    form = {projectShortNameList:''};
    lists = {};
    user = {role: "public", signedIn: false, firstName: "Public", lastName: "User"};
    showSelectProjects = false;
    domainGlobals: any = {};
    alerts: any = [];
    fieldErrors = {};
    arrayFieldErrors = {};
    addNewPhone = false;
    doneWithLimitedLogin = false;
    dataCollectionEnabled = false;
    gte16 = false;
    gte16no = false;

    constructor(
      private commonService: CommonService,
      private h: Ub3httpService,
      public cookies: CookieService,
      private modalService: NgbModal,
      public sweetAlert: SweetAlert
    ){
          t = scope = this;
          clog = t.commonService.ub3consoleLog;
          http = h;
          t.state = t.commonService.getSharedObject('app','state');
          t.fieldErrors = t.commonService.getSharedObject('warning', 'fieldErrors');
          t.arrayFieldErrors = t.commonService.getSharedObject('warning', 'arrayFieldErrors');
          t.flags.fldHasError = false;
    }

    ngOnInit(): void {
      var accReactSessionExists = t.state.user && t.state.user.signedIn && (t.state.user.role == 'limited') && (t.state.user.limited_type == 'REACTIVATE');
      if (!accReactSessionExists){
        t.commonService.signOut(null, scope, true);
      }
      validationModule.overWriteScope(scope);
      t.user = t.state.user;
      if (t.user.signedIn && (t.user.role == 'limited')){
        t.gte16 = true;
      }
      accountReactModule.init(http, http, t.cookies, t, 'Reactivate an Account', false, Promise, setTimeout, false);
      document.body.classList.add('public-page');
      t.commonService.doWhenAPICallsDone(function(){
              t.valAllAcUpdFields(t.form);
              t.flags.isReactivateSurvey = false;
              t.commonService.whoami(function(d){
                if (!d.signedIn){
                    // clear out the name field on top of screen
                    var e = document.getElementById('nameOfUser');
                    if (e){
                        t.user = t.state.user = {role: 'public'};
                    }
                }
              });
      });
    }
    setDataCollFlag(v){
        clog(69, {v:v});
        t.dataCollectionEnabled = v;
    }
    onDataCollectionExit(){
    }
    cancelAccountReactivation = function(){
      localStorage.removeItem('limLoginReason');
      t.commonService.signOut(null, scope, false);
    }

    valAllAcUpdFieldsH(){
        t.valAllAcUpdFields(t.form);
    }
    //f = form
    valAllAcUpdFields(f){
      t.flags.fldHasError = false;
      accountReactModule.validateAllFields(f, function(){
          //f.orcid = t.commonService.tweakOrcid(f.orcid);
          f.scopusId = t.num11(f.scopusId);
          var key, elem;
          // check if we need to show an option to 'add a new phone number'
          t.addNewPhone = f.accountPhones && f.accountPhones.filter(function(item){
            return (!item.status) || (item.status == 'Active') ;
          }).length < 2;
          t.commonService.doWhenAPICallsDone(function(){
            t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
            t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
            t.disableReactivateSubmit =  accountReactModule.favorDataNeededOrPoliciesNotAcknowledged(f, scope);
            //check whether to enable Foreign National Info button or not (special case: issue#3701)
            t.flags.allReqdFieldsComplete = t.flags.updFormComplete;
            for (key in t.arrayFieldErrors.accountEmails) {
              elem = t.arrayFieldErrors.accountEmails[key];
              if(elem.email && elem.email.error){
                t.flags.allReqdFieldsComplete = false;
              }
            }
          });
      });
    }

    num11(str){
      var i, c, result = '';
      if (!str) return '';
  
      for (i=0; (i < str.length); i++){
          c = str[i];
          if (('0' <= c) && (c <= '9')){
              result = result + c;
          }
      }
      result = result.slice(0,11);
      return result;
    }

    getInstitutionId(name){
      return t.commonService.getInstitutionId(name, scope.lists);
    }

    directingToFavor() {
      accountReactModule.directingToFavor(window, http, scope.form, scope.flags, scope.lists, t.cookies, setTimeout, null);
    }

    setPrimaryEmail(obj, id, email, form) {
      t.selectPrimaryItem(obj, scope.form.accountEmails);
      t.validateEmail(obj, email);
      if(obj.valid){
        t.hasPrimary(scope.form.accountEmails);
        accountReactModule.validateList('accountEmails', obj, id);
      }
      t.commonService.doWhenAPICallsDone(function(){
        t.valAllAcUpdFields(form);
      });
    }
    validateEmail = function (obj, email) {
      accountReactModule.validateEmail(obj, email);
    }

    selectPrimaryItem(row, array){
      // checkboxes in the browser take a few milliseconds to set the DOM after the click
      // wait for that to happen, then run this loop below
      setTimeout(function(){
        array.forEach(function(b){
            b.primary = (row === b);
        })
      }, 300);
    }
    setGTE16(){
        t.gte16 = true;
     }
     setGTE16no(){
         t.gte16no = true;
     }
     not16goBackNow(){
         window.location.hash = "/";
     }

    selectPrimaryInstitution(row, array){

      // checkboxes in the browser take a few milliseconds to set the DOM after the click
      // wait for that to happen, then run this loop below
      setTimeout(function(){
        if (!row || !row.affiliation || (row.affiliation == '') || (row.affiliation && row.affiliation.length < 3)){
            return;
        }
        array.forEach(function(b){
            b.primaryInstitution = (row === b);
        });
      }, 200);
    }
    goToTopOfCitizenArea(whichPage){
      if (whichPage == 'req'){
          var elem = document.getElementById('arCit');
          elem.scrollIntoView();
      }
      if (whichPage == 'upd'){
          var elem = document.getElementById('auCit');
          elem.scrollIntoView();
      }
      if (whichPage == 'react'){
          var elem = document.getElementById('auCit');
          elem.scrollIntoView();
      }
    }
    updateAccountInfo(beforeRedirect, callback){
      scope.alerts = [];
      scope.confirmBtn = false;
      accountReactModule.saveAccountInfo(scope.form, scope.flags, scope.lists, Promise, scope.alerts, beforeRedirect, function(allSaved){
        t.confirmBtn = scope.confirmBtn;
        t.alerts = scope.alerts;
        if (callback) callback(allSaved);
      });
    };

    submitReactivate(){
      t.disableReactivateSubmit = true;
      t.commonService.removeNewDeletedObjs(scope.form, scope.flags.arrayFieldErrors);
      accountReactModule.submitReactivate(scope, false, function(allSaved){
        if (allSaved){
            t.flags.reactivateSubmitted = true;
            t.commonService.signOut(null, t, true);

        }
      });
    }

    tryToGoToFavor(){

      // do validation first
      //$scope.validateAccountUpdate();
      accountReactModule.validateAllFieldsNow(scope.form);

      if (scope.flags.accountUpdate && (scope.flags.missing == 'Required: Status, ')){
          scope.flags.missing = '';
      }
      if (!scope.flags.missing){
          scope.flags.preFavorNotice=false; 
          t.updateAccountInfo(true);
          t.writeCookie('anl-UB3-act-react-pj', scope.form.projectShortNameList, 60);
          t.commonService.doWhenAPICallsDone(function(){
            //FIXME: redirect to favor website only when no errors are there
            // t.directingToFavor();
            // scope.flags.loading=true;
            scope.flags.loading = !scope.flags.error;
            scope.flags.fldHasError = scope.flags.error;
            if(!scope.flags.error) { t.directingToFavor(); }
          });
      }else{
          scope.flags.preFavorReq = scope.flags.missing;
          setTimeout(function(){scope.flags.preFavorReq = null;}, 2000);
      }
    }
    saveAndReturnLater(){
      accountReactModule.saveAndReturnLater(scope);
    }

    confirmDelete(obj, form, objName){
      obj.status = 'Deleted';
      t.commonService.removeEmailErrClass(obj.id, objName);
      t.valAllAcUpdFields(form);
    }

    setUUPreferredName(){
        accountReactModule.setPreferredName(scope.form);
        t.valAllAcUpdFields(scope.form);
    };

    accountAddItem(type){
      if(type === 'Phones'){t.addNewPhone = false;}
      editRecordModule.addItem(scope.form, scope.flags, type);
    }

    closePjSelect(){
      if (t.form.projectShortNameList){

        // save the selected project in a cookie
        var c = t.commonService.getLocalStorage('anl-UB3-LLF');
        if (c && (c != '')){
            c.projectShortNameList = t.form.projectShortNameList;
            t.commonService.setLocalStorage('anl-UB3-LLF', c);
        }

        t.showSelectProjects = false;
        accountReactModule.initFormValues(t);
        t.commonService.doWhenAPICallsDone(function(){
          t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
          t.lists.simpleInstitutions = [];
          for (var i in t.lists.institutions){
              t.lists.simpleInstitutions.push(t.lists.institutions[i].name);
          }

          t.valAllAcUpdFields(t.form);
        });
      }
    }

    afterLimitedLogin(){
      localStorage.setItem('limLoginReason', 'accountReactivate');
      t.commonService.setUser(t.state, function(){  
        t.flags.level = 5;
        t.flags.answerVerified = true;
        t.doneWithLimitedLogin = true;
        t.commonService.getLimitedProjectsList(t.lists);
        var c = t.commonService.getLocalStorage('anl-UB3-LLF');
        if (c && (c != '')){
            if (c.projectShortNameList){
              t.form.projectShortNameList = c.projectShortNameList;
              if (!t.form.firstName){
                t.closePjSelect();
              }
            }
        }
      });

      t.commonService.doWhenAPICallsDone(function(){
        t.lists.activeProjects = t.commonService.sortArrayOnKey(t.lists.activeProjects, 'name', false);
      });
    }
 
    onNoCountry(obj) {
      accountReactModule.onNoCountry(obj, scope.form);
    }

      // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
    convertUTCtoCDT(s){
      return t.commonService.convertUTCtoCDT(s);
    }
      // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
    hasPrimary(arr){
      return t.commonService.hasPrimary(arr);
    }
    // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
    dialCode(name){
          return t.commonService.getCountryCode(name, scope.lists);
    }
    addressChange(value){
      var workAddr, shipAddr;
      if (scope.form['addressVal']){
          workAddr = scope.form.accountAddresses[0];
          shipAddr = scope.form.accountAddresses[1];
          Object.keys(workAddr).forEach(function(key,val){
              if (scope.form.addressVal && key != 'label' && key != 'id' ){
                shipAddr[key] = workAddr[key];
              }
          });
          //$timeout(function(){
          accountReactModule.validateList('accountAddresses', shipAddr, shipAddr.id);
          //}, 2000);
      }
    }
    aupLocateByZip(formName, obj, row) {
      t.commonService.locateByZipAup(formName, obj, row, scope.form);
    }
    removeNmiErr(nmi) {
      if (nmi && document.querySelector("#nmi-div").classList.contains("error-section"))
        document.querySelector("#nmi-div").classList.remove('error-section');
    }

    // specific to the states in USA
    onStateChange(row, form){
      t.commonService.onStopCalling(1000, getStAbr);

      function getStAbr(){
        var ss = t.lists.usaStates.filter(function(st){
          return st.name === row.state;
        });
        row.state = ss[0] ? ss[0].abr : '';
        t.valAllAcUpdFields(form);
      }
    }

    //ngbTypeahead for state abbreviation
    searchState = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          var sl = term.toLowerCase();
          return t.lists.usaStates.filter(function(c){
            return ((c.abr.toLowerCase().indexOf(sl) >= 0) || (c.name.toLowerCase().indexOf(sl) >= 0));
          }).map(function (c) {
            return (c.abr, c.name);
          });
        })
    )

    countryChange(row, form){
      t.commonService.onCountryChange(row, t.lists, function(){
        t.valAllAcUpdFields(form);
      });
    }

    //ngbTypeahead
    instTypeAhead = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
        : t.lists.simpleInstitutions && t.lists.simpleInstitutions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1))
    )

    //ngbTypeahead for country code
    // currently we're not using this.
    // keeping for future reference

    searchCountry = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.countries.filter(function(c){
          if(/\d/.test(term)){
            return c.dialCode === term;
          } else {
            return (c.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
          }
        }).map(function (c) {
          return c.name;
        });
      })
    )

    verifyAccount(){
      t.commonService.scrollToHasError(scope, function(){
        scope.confirmBtn = (!scope.flags.errorStatus && t.confirmSaveChecked);
        t.disableReactivateSubmit =  accountReactModule.favorDataNeededOrPoliciesNotAcknowledged(t.form, scope);
      });
    }
    writeCookie (key, value, minutes) {
        var date = new Date();
    
        // Default at 60 minutes
        minutes = minutes || 60;
    
        // Get unix milliseconds at current time plus number of minutes
        date.setTime(+ date + (minutes * 1000 * 60));
    
        window.document.cookie = key + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    };

    signOut(){
      t.commonService.signOut(null, scope, false);
    };

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if(t.state.user && t.state.user.signedIn && (t.state.user.role === 'limited')){
        return t.sweetAlert.confirmSwitchRequests();
      } else {
        return true;
      }
    }

}

