

<div class="reactivate-main-page" >

    <div *ngIf="!flags.reactivateSubmitted">
       <div class="reversed-white">
          <h1>Reactivate an ALCF account</h1>
          <p *ngIf="!flags.answerVerified">
             If you have an existing ALCF username that is no longer active, please use this form to get your account re-activated. 
             Please remember to input the primary email address associated with your old account to get the access code.
          </p>
       </div>
       <div class="card ub3-card" id="ageGte16" *ngIf="!gte16">
         <div class="gteHead">Please confirm you are at least 16 years old.</div>
         <div class="gteBody">To proceed with reactivating an account, you must be at least 16 years of age.</div>
         <div class="gteBody" *ngIf="gte16no">
            <font color="red">
            Your age does not permit you to reactivate an account at this time.
            </font>
         </div>
         <div *ngIf="!gte16no">
            <button id="set16" class="btn btn-primary" (click)="setGTE16()">
                <div>YES</div>
                <div class="atl16">I am at least 16 years old</div>
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button id="set16no" class="btn btn-primary" (click)="setGTE16no()">
                <div>NO</div>
                <div class="atl16">I am not at least 16 years old</div>
            </button>
         </div>
         <div *ngIf="gte16no">
            <button id="not16goBack" class="btn btn-primary" (click)="not16goBackNow()">
                <div>Back</div>
            </button>
         </div>
       </div>
       <limited-login 
                *ngIf="(!doneWithLimitedLogin) && gte16" 
                [(flags)]="flags" 
                [(user)]="user" 
                [after]="afterLimitedLogin">
       </limited-login>

       <data-collection 
                 *ngIf="doneWithLimitedLogin"
                 option="accountReactivate"
                 [callback]="onDataCollectionExit" 
                 [afterEnableCheck]="setDataCollFlag">
       </data-collection>

    </div>
 
  <span *ngIf="!dataCollectionEnabled">
    <div *ngIf="flags.answerVerified && !flags.reactivateSubmitted">
       <p class="reversed-white" *ngIf="!flags.error && !form.projectShortNameList">
          Please select one project. Project PI will approve your reactivation request. 
          If you have additional projects you would like to include or any other information about 
          this reactivation request, you can enter them in the "Comment" field.
       </p>
       <p class="reversed-white" *ngIf="!flags.error && form.projectShortNameList">
             Please verify that your contact information is up to date. Your information will be 
             shared with Argonne National Laboratory and may also be shared with the Department of Energy.
       </p>
       <div class="ub3-card">
          <div class="card-body">
             <table>
                <tbody>
                   <tr>
                      <td>
                         <p *ngIf="flags.error">
                            <span ng-bind="flags.error"></span>
                         </p>
                         <h2>Projects</h2>
                         <div *ngIf="!flags.error">
                            <table>
                               <tr style="min-height: 50px;">
                                  <td>
                                     <div class="form-group floating-label-wrapper">
                                        <label for="project"></label>
                                        <input type="text" class="form-control" id="project"
                                           placeholder="Project"
                                           [(ngModel)]="form.projectShortNameList"
                                           (focus)="showSelectProjects = true"
                                           [disabled]="showSelectProjects"
                                           with-floating-label>
                                     </div>
                                  </td>
                                  <td>
                                     <warning fid="project"></warning>
                                  </td>
                               </tr>
                               <tr *ngIf="showSelectProjects">
                                  <td>
                                       <select-projects 
                                          [(form)]="form" 
                                          field='projectShortNameList' 
                                          [max]="1"
                                          [projects]="lists.activeProjects"
                                          [after]="closePjSelect"
                                          >
                                       </select-projects>
                                  </td>
                               </tr>
                               <tr *ngIf="!form.accountStatus || lists.errorAccountUpdate">
                                  <td>
                                     <br><br><a id='cancelAccountReactivation' (click)="cancelAccountReactivation()" class="nav-link">
                                          Cancel Reactivation Request
                                     </a><br><br>
                                  </td>
                               </tr>
                            </table>
                            <div class="alert alert-danger" *ngIf="lists.errorEditRecord">
                               Your session has expired. Please select "Cancel Reactivation Request", and try again.
                            </div>
                         </div>
                      </td>
                   </tr>
                </tbody>
             </table>
          </div>
       </div>
    </div>

    <!--  ACCOUNT EDIT FORM -->
    <div class="update-account" *ngIf="form.projectShortNameList" id="account-editing-form">

      <div class="ub3-card" *ngIf="!user.signedIn && !flags.reactivateSubmitted">
          <font color=red>Please sign in</font>
      </div>
      <div class="update-account ub3-card" *ngIf="user.signedIn">
         <div class="card-body">
            <div class="alert alert-danger" *ngIf="lists.errorAccountUpdate">{{lists.errorAccountUpdate}}</div>
            <div *ngIf="!lists.errorAccountUpdate">
               <div *ngIf="flags.bootstrapAlert === 'editRecordError'">
                  <div uib-alert *ngFor="let alert of alerts"
                    [ngClass]="'alert-' + (alert.type || 'warning')"
                     close="closeAlert(i)" dismiss-on-timeout="99999">{{alert.msg}}
                  </div>
               </div>
               <div uib-alert *ngFor="let alert of alerts"
                 [ngClass]="'alert-' + (alert.type || 'warning')"
                  close="closeAlert(i)" dismiss-on-timeout="99999">{{alert.msg}}
               </div>
               <div>
                  <div class="form-group floating-label-wrapper">
                     <label for="comment">Comment</label>
                     <textarea autosize type="text" rows="2" class="form-control autosize"
                        id="comment"
                        placeholder="Comment"
                       (change)="valAllAcUpdFields(form)"
                        [(ngModel)]="form.comment"
                        with-floating-label></textarea>
                  </div>
                  <warning fid="comment"></warning>
               </div>
               <h2 class="accountDetails">Account Details</h2>
               <table id="updateAccDetails" class="table-condensed table-user-details">
                  <tbody>
                     <tr *ngIf="!flags.hideAccountSection">
                        <td>
                           Username:
                        </td>
                        <td>
                           <span id="userName">{{form.userName}}</span>
                        </td>
                     </tr>
                     <tr *ngIf="!flags.hideAccountSection">
                        <td>
                           Status:
                        </td>
                        <td>
                           <span [textContent]="form.accountStatus" *ngIf="flags.accountUpdate"></span>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           Account Deactivation Date:
                        </td>
                        <td>
                           <span [textContent]="convertUTCtoCDT(form.deactivationDate)" *ngIf="flags.accountUpdate"></span>
                        </td>
                     </tr>
                     <tr *ngIf="!flags.hideAccountSection && !form.usCitizen">
                        <td>
                           593 Expiration Date:
                        </td>
                        <td>
                           <span *ngIf="flags.expirationDate593 !== null"
                              [textContent]="convertUTCtoCDT(flags.expirationDate593)"></span>
                        </td>
                     </tr>
                     <tr *ngIf="lists.accountSystems">
                        <td>
                           Computing Resources:
                        </td>
                        <td class="resourceName">
                           <div class="resoureList">
                              <ng-container *ngIf="!flags.hideAccountSection">
                                 <div *ngFor="let record of lists.accountSystems">
                                     <span>{{record.resourceName}}{{!$last && lists.accountSystems.length > 1 ? ', ' : ''}}</span>
                                 </div>
                              </ng-container>
                           </div>
                        </td>
                     </tr>

                  </tbody>
               </table>
               <div  class="account cryptoKeyOption mb12">
                  <ul>
                     <li>
                        Crypto Key Token:
                     </li>
                     <li class="inputCheckbox">
                        <div class="radios">
                           <p>
                              <input type="radio"
                                     name="cryptoKeyOption"
                                     id="crypto1"
                                     [(ngModel)]="form.cryptoKeyOption"
                                     value="Physical"
                                     ngDefaultControl
                                     (change)="valAllAcUpdFields(form)"
                                     checked>
                              <label for="crypto1">Physical</label>
                           </p>
                           <p>
                              <input type="radio"
                                     name="cryptoKeyOption"
                                     id="crypto2"
                                     [(ngModel)]="form.cryptoKeyOption"
                                     value="Mobile"
                                     ngDefaultControl
                                     (change)="valAllAcUpdFields(form)">
                              <label for="crypto2">Mobile</label>
                           </p>
                        </div>
                     </li>
                  </ul>
               </div>
               <!-------- UNIX SHELL ------->
               <!-- replace hard coded values with dropdown --->
               <div  class="account mb12">
                  <ul>
                     <li>
                        Unix Shell:
                     </li>
                     <li class="inputCheckbox">
                        <div class="radios">
                           <p>
                              <input type="radio"
                                     name="shell"
                                     id="shell1"
                                     [(ngModel)]="form.shell"
                                     value="/bin/tcsh"
                                     ngDefaultControl
                                     (change)="valAllAcUpdFields(form)"
                                     checked>
                              <label for="shell1">tcsh</label>
                           </p>
                           <p>
                              <input type="radio"
                                     name="shell"
                                     id="shell2"
                                     [(ngModel)]="form.shell"
                                     value="/bin/bash"
                                     ngDefaultControl
                                     (change)="valAllAcUpdFields(form)">
                              <label for="shell2">bash</label>
                           </p>
                           <p>
                              <input type="radio"
                                     name="shell"
                                     id="shell3"
                                     [(ngModel)]="form.shell"
                                     value="/bin/zsh"
                                     ngDefaultControl
                                     (change)="valAllAcUpdFields(form)">
                              <label for="shell3">zsh</label>
                           </p>
                        </div>
                     </li>
                  </ul>
               </div>
               <!---------- NAME AND EMAIL ---------->
               <h2 class="clear">Name and Email</h2>
               <!-- First Name -->
               <div class="clear form-group floating-label-wrapper">
                 <input type="text"
                     class="form-control inBox"
                     id="firstName"
                     placeholder=" "
                     [(ngModel)]="form.firstName"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)">
                 <label for="firstName" class="inputLbl">Legal First/Given Name</label>
                 <warning fid="firstName"></warning>
               </div>
               <!-- Middle Name -->
               <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError && flags.hasError.middleName}" id="nmi-div">
                 <input type="text"
                     class="form-control inBox"
                     id="middleName"
                     placeholder=" "
                     [(ngModel)]="form.middleName"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)"
                     maxlength="40">
                 <label for="middleName" class="inputLbl">Legal Middle Name</label>
                 <span class="cBox noMiddleName">
                    <input type="checkbox"
                          [(ngModel)]="form.nmi" 
                          id="nmi"
                          class="align-checkbox"
                          (change)="valAllAcUpdFields(form); removeNmiErr(form.nmi)">
                    <span class="align-text">No Middle Name</span>
                 </span>
                  <warning fid="middleName"></warning>
               </div>
               <!-- Last Name -->
               <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError.lastName}">
     
                 <input type="text"
                     class="form-control inBox"
                     id="lastName"
                     placeholder=" "
                     [(ngModel)]="form.lastName"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)">
                 <label for="lastName" class="inputLbl">Legal Last/Family Name</label>
                  <warning fid="lastName"></warning>
               </div>
               <!-- Preferred Name -->
               <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError.preferredName}">
                 <input type="text"
                     class="form-control inBox"
                     id="preferredName"
                     placeholder=" "
                     [(ngModel)]="form.preferredName"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)"
                     (focus)="setUUPreferredName()">
                 <label for="preferredName" class="inputLbl">Preferred Name</label>
                 <warning fid="preferredName"></warning>
               </div>
               <!-- Emails -->
               <div [hidden]="f.status === 'Deleted'" *ngFor="let f of form.accountEmails; let i = index">
                  <div class="form-group floating-label-wrapper">
                    <input type="text"
                        class="form-control inBox email-section"
                        id="email{{i}}"
                        placeholder=" "
                        [(ngModel)]="f.email"
                        autocomplete="nope"
                        (keyup)="valAllAcUpdFields(form)">
                    <label for="email{{i}}" class="inputLbl">Email Address</label>
                     <!--f.status ='Deleted' should not be set until confirmed 'YES' in modal -->
                     <div class="cBox">
                        <span>
                           <input type="checkbox" class="align-checkbox"
                            id="prim-email-{{i}}"
                            (ngModelChange)="setPrimaryEmail(f, f.id, f.email, form)"
                            [(ngModel)]="f.primary">
                        </span>
                        <span class="align-text">Primary</span>
                        <div *ngIf="!hasPrimary(form.accountEmails)" class="warning noPrimary">
                           Primary is required
                        </div>
                     </div>
                     <div class="primary-select">
                        <span *ngIf="!f.primary"  title="Delete Email" class="delete-item">
                           <span class="fas fa-trash-alt"
                                 id="del-email-{{i}}"
                                 (click)="confirmDelete(f, form, 'email');">
                           </span>
                           <span class="status-pending" *ngIf="f.status ==='Pending'">
                              Pending
                           </span>
                        </span>
                     </div>
                     <warning-a fn="accountEmails" fid="email" row={{f.id}} id="warnEmail{{f.id}}"></warning-a>
                  </div>
               </div>
               <div class="form-group section-ht">
                  <span 
                     class="fas fa-plus-circle addAdditional"
                     id="addMoreEmails"
                     (click)="accountAddItem('Emails'); valAllAcUpdFields(form)"></span>
                  <span class="addIcon">Add additional emails</span>
               </div>
               <!-- Phone Numbers -->
               <div class="section-info update-account-phoneSection">
                  <h2>Phone Numbers</h2>
                  <table class="table-condensed table-user-details" *ngIf="form.accountPhones && form.accountPhones.length">
                     <tbody>
                        <tr [hidden]="f.status === 'Deleted'" *ngFor="let f of form.accountPhones;  let i = index">
                           <td>
                              <table>
                                 <tr class="tabledata">
                                    <td class="update-account country">
                                       <div class="floating-label-wrapper">
                                          
                                          <input type="hidden" id="ph-country-{{i}}" value="{{f.phoneCountry}}"> <!-- for Cypress -->
                                          <input type="text"
                                             [(ngModel)]="f.selectedCountry"
                                             id="country{{i}}"
                                             placeholder="{{f.phoneCountry ? dialCode(f.phoneCountry) : 'Country Name'}}"
                                             autocomplete="nope"
                                             class="form-control"
                                             name="selectedCountry"
                                             (blur)="countryChange(f, form)"
                                             ngDefaultControl
                                             [ngbTypeahead]="searchCountry"
                                             ngbTooltip="Enter country name for numeric country code"
                                             placement="bottom"
                                             />
     
                                          <warning-a fn="accountPhones" fid="phoneCountry" row={{f.id}}></warning-a>
                                       </div>
                                    </td>
                                    <td class="update-account number phone-number min-wid-320">
                                       <div class="floating-label-wrapper">
                                          <input type="text" class="form-control"
                                             id='phoneNumber{{i}}'
                                             placeholder="phone number"
                                             [(ngModel)]="f.phoneNumber"
                                             autocomplete=“nope”
                                             (keyup)="valAllAcUpdFields(form)"
                                             />
                                          <warning-a fn="accountPhones" fid="phoneNumber" row={{f.id}} id="warnPhone{{f.id}}"></warning-a>
                                       </div>
                                    </td>
                                    <td class="update-account extension min-wid-320">
                                       <div class="floating-label-wrapper ">
                                          <input type="text" id="extension{{i}}"
                                             placeholder="Ext.(opt.)"
                                             [(ngModel)]="f.extension"
                                             class="form-control"
                                             autocomplete="nope"
                                             (keyup)="valAllAcUpdFields(form)">
                                          <warning-a fn="accountPhones" fid="extension" row={{f.id}}></warning-a>
                                       </div>
                                    </td>
                                    <td class="phoneLabel">
                                       <div>
                                          <div class="floating-label-wrapper ">
                                             <select 
                                                [(ngModel)]="f.phoneLabel" 
                                                class="form-control"
                                                style="width:90px" 
                                                id="phoneLabel{{i}}"
                                                placeholder="Type"
                                                autocomplete="nope"
                                                (change)="valAllAcUpdFields(form)"
                                                (blur)="valAllAcUpdFields(form)"
                                                (keyup)="valAllAcUpdFields(form)"
                                                >
                                                <option value='Mobile'>Mobile</option>
                                                <option value='Work'>Work</option>
                                                <option value='Home'>Home</option>
                                             </select>
                                             <warning-a fn="accountPhones" fid="phoneLabel" row={{f.id}}></warning-a>
                                          </div>
                                       </div>
                                    </td>
                                    <td class="select-primary-phone">
     
                                       <span class="up-acc cBox" (click)="selectPrimaryItem(f, form.accountPhones); valAllAcUpdFields(form)">
                                         <input type="checkbox"
                                                id="prim-phone-{{i}}"
                                                [(ngModel)]="f.primary">
                                         <span>Primary Phone</span>
                                       </span>
                                       <span  title="Delete phones" *ngIf="!f.primary"
                                         autocomplete="nope"
                                         style="margin-right: 10px;">
                                         <span
                                            id="del-phone-{{i}}"
                                            class="fas fa-trash-alt"
                                            placeholder="Phone Status"
                                            (click)="confirmDelete(f, form, 'phoneNumber');">
                                         </span>
                                         <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                      </span>
                                    </td>
                                 </tr>
                              </table>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <!---------- PHONE NUMBERS ---------->
                  <div>
                       <div *ngIf="addNewPhone" class="form-group section-ht">
                         <span class="fas fa-plus-circle addAdditional"
                             (click)="accountAddItem('Phones'); valAllAcUpdFields(form);" id="addBtn"></span>
                         <span class="addIcon">Add additional phone numbers</span>
                       </div>
                  </div>
               </div>
               <!---------- ADDRESSES ---------->
               <ng-container *ngIf="form.accountAddresses">
               <!-- 
               <div *ngFor="let f of form.accountAddresses | orderBy: reverse:flags.addressOrder ; let i = index "
               -->
               <div *ngFor="let f of form.accountAddresses; let i = index"
                 [ngClass]="{'left-group' : f.label == 'Work', 'right-group' : f.label == 'Shipping'}">
                  <div class="form-adress">
                     <h2 class="addressHeader" [ngClass]="{shippingAdd: f.label == 'Shipping'}">{{f.label}} Address</h2>
                     <div  *ngIf="f.label == 'Shipping'">
                           <input 
                              type="checkbox" 
                              id="same-as-work-cb" 
                              [(ngModel)]="form.addressVal" 
                              (change)="valAllAcUpdFields(form)">
                           <label for="same-as-work-cb" id="sameAsWork">Same as Work Address</label>
                     </div>
                     <div class="form-group floating-label-wrapper" style="clear: both;">
                        <select
                           class="form-control inBox"
                           id="{{f.label}}-country"
                           [(ngModel)]="f.country"
                           placeholder=" "
                           [disabled]="f.label == 'Shipping' && form.addressVal "
                           (change)="onNoCountry(f) ;valAllAcUpdFields(form);addressChange(f)"
                           (blur)="zipHint = false  ;valAllAcUpdFields(form);addressChange(f);"
                        >
                           <option value="" disabled>Select a Country</option>
                           <option *ngFor="let c of lists.countries"
                                   value="{{c.name}}">
                               {{c.name}}
                           </option>
                        </select>
                        <label for="{{f.label}}-country" class="inputLbl">Country</label>
                        <warning-a fn="accountAddresses" fid="country" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country == 'United States'" class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="zip{{i}}"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           autocomplete="nope"
                           [(ngModel)]="f.zip"
                           (change)="aupLocateByZip('accountAddresses', f, i); valAllAcUpdFields(form);addressChange(f)"
                           (keyup)="valAllAcUpdFields(form)"
                           >
                        <label for="zip{{i}}" class="inputLbl">Zip</label>
                        <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                     </div>
                     <div class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="street1{{i}}"
                           autocomplete="nope"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           [(ngModel)]="f.street1"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="street1{{i}}" class="inputLbl">Address Line 1</label>
                        <warning-a fn="accountAddresses" fid="street1" row={{f.id}}></warning-a>
                     </div>
                     <div class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="street2{{i}}"
                           autocomplete="nope"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           [(ngModel)]="f.street2"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="street2{{i}}" class="inputLbl">Address Line 2 (optional)</label>
                        <warning-a fn="accountAddresses" fid="street2" row={{f.id}}></warning-a>
                     </div>
                     <div class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="street3{{i}}"
                           autocomplete="nope"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           [(ngModel)]="f.street3"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="street3{{i}}" class="inputLbl">Address Line 3 (optional)</label>
                        <warning-a fn="accountAddresses" fid="street3" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country == 'United States'" class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="city{{i}}"
                           autocomplete="nope"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           [(ngModel)]="f.city"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="city{{i}}" class="inputLbl">City</label>
                        <warning-a fn="accountAddresses" fid="city" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country !='United States'" class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="city{{i}}"
                           autocomplete="nope"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           [(ngModel)]="f.city"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="city{{i}}" class="inputLbl">City/Town/Locality</label>
                        <warning-a fn="accountAddresses" fid="city" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country == 'United States'"
                        class="form-group floating-label-wrapper">
                        <input 
                           [(ngModel)]="f.state"
                           type="text"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           autocomplete="nope"
                           id='state{{i}}'
                           class="form-control inBox"
                           placeholder=" "
                           (focus)="zipHint = true"
                           (ngModelChange)="onStateChange(f, form)"
                           *ngIf="!mobile"
                           [ngbTypeahead]="searchState">
     
                        <label for="state{{i}}" class="inputLbl" *ngIf="!mobile">State Abbreviation</label>
                        <!--Mobile scrolling at bottom of screen when select gets focus-->
                        <select 
                           [(ngModel)]="f.state" 
                           class="form-control inBox"
                           id='state{{i}}'
                           autocomplete="nope"
                           placeholder="State Abbreviation"
                           (change)="valAllAcUpdFields(form); addressChange(f)"
                           (blur)="valAllAcUpdFields(form)"
                           (keyup)="valAllAcUpdFields(form)"
                           *ngIf="mobile">
                           <option value="">State Abbreviation</option>
                           <option *ngFor="let f of lists.usaStates" value="{{f.abr}}">{{f.name}}</option>
                        </select>
                        <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country != 'United States'" class="form-group floating-label-wrapper">
                        <input 
                           [(ngModel)]="f.state"
                           type="text"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           autocomplete="nope"
                           id='state{{i}}'
                           class="form-control inBox"
                           placeholder=" "
                           (focus)="zipHint = true"
                           (change)="addressChange(f)"
                           (blur)="valAllAcUpdFields(form)"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="state{{i}}" class="inputLbl">State/Province Region</label>
                        <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                     </div>
                     <div *ngIf="f.country != 'United States'" class="form-group floating-label-wrapper">
                        <input type="text" class="form-control inBox" id="zip{{i}}"
                           [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                           placeholder=" "
                           autocomplete="nope"
                           [(ngModel)]="f.zip"
                           (change)="valAllAcUpdFields(form); addressChange(f)"
                           (keyup)="valAllAcUpdFields(form)">
                        <label for="zip{{i}}" class="inputLbl">Zip/Postal Code</label>
                        <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                     </div>
                  </div>
                  <script type="text/ng-template" id="abrStateSelect.html">
                     <a>
                     <span [innerHtml]="match.model.name | uibTypeaheadHighlight:query">
                     </span>
                     </a>
                  </script>
               </div>
               </ng-container>
               <!---------- EMPLOYER ---------->
               <div class="section-info" *ngIf="!flags.hideAccountSection">
                  <h2>Employer</h2>
                  <br><font size=-1>(The institution that is responsible for you and your work at the ALCF. Select 'Individual' if self-employed.)</font>
                  <div *ngFor="let f of form.accountInstitutions ; let i = index"
                       [hidden]="f.status === 'Deleted'" 
                       class="acountInst">
                     <div class="form-group floating-label-wrapper affiliations">
                        <input 
                           type="text"
                           id='affiliation{{i}}'
                           style="width:80%"
                           [(ngModel)]="f.affiliation"
                           name="affiliation"
                           ngDefaultControl
                           placeholder="Employer"
                           (ngModelChange)="valAllAcUpdFields(form)"
                           (keyup)="valAllAcUpdFields(form)"
                           class="form-control"
                           maxlength="50"
                           [disabled]="(f.id >= 0 || f.primaryInstitution===true)"
                           [ngbTypeahead]="instTypeAhead"
                           typeahead-show-hint="true"
                           typeahead-min-length="2"
                           (blur)="f.institutionId = getInstitutionId(f.affiliation); valAllAcUpdFields(form)">

                        <input type="checkbox"
                               id="primaryCheckBox{{i}}"
                               class="align-inst-sec"
                               [(ngModel)]="f.primaryInstitution"
                               (click)="selectPrimaryInstitution(f, form.accountInstitutions);valAllAcUpdFields(form)">
                        <span class="align-inst-sec">Primary</span>
     
                        <span *ngIf="f.primaryInstitution !==true" title="Delete Institution"
                           class="delete-item">
                                 <span class="fas fa-trash-alt" id="deleteInstitution{{i}}"
                                     (click)="confirmDelete(f, form, 'affiliation');">
                                 </span>
                        </span>
                        <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                        <warning-a fn="accountInstitutions" fid="affiliation" row={{f.id}} id="warnInstn{{f.id}}">
                        </warning-a>
                     </div>
                  </div>
                  <div id="additionalInst">
                     <span class="fas fa-plus-circle pointCursor"
                           id="addAdditionalInstitution"
                           (click)="accountAddItem('Institutions'); valAllAcUpdFields(form);">
                     </span> 
                     <span class="addIcon">
                        Add additional employers
                     </span>
                  </div>
               </div>
               <!---------- Career Level ---------->
               <h2>Career Level</h2>
               <div class="form-group floating-label-wrapper ">
                 <select 
                    [(ngModel)]="form.employment_level"
                    class="form-control inBox"
                    id="employment_level"
                    placeholder=" "
                    (blur)="valAllAcUpdFields(form)"
                    (change)="valAllAcUpdFields(form)">
                    <option value="" disabled></option>
                    <option *ngFor="let f of lists.careerLevels" value="{{f.name}}">{{f.name}}</option>
                 </select>
                 <label for="employment_level" class="inputLbl">Choose your career level</label>
                 <warning fid="employment_level"></warning>
               </div>
      
               <!---------- ORCID ---------->
               <h2>ORCID</h2>
               <div class="form-group floating-label-wrapper">
                  <input type="text" class="form-control inBox"
                     id="orcid" orcid-scopus="'orcid'"
                     placeholder=" "
                     [(ngModel)]="form.orcid"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)">
                  <label for="orcid" class="inputLbl">Enter your ORCID</label>
                  <warning fid="orcid"></warning>
               </div>
               <!---------- SCOPUSID ---------->
               <h2>SCOPUSID</h2>
               <div class="form-group floating-label-wrapper">
                  <input type="text" class="form-control inBox" id="scopusId" orcid-scopus="'scopus'"
                     placeholder=" "
                     [(ngModel)]="form.scopusId"
                     autocomplete="nope"
                     (keyup)="valAllAcUpdFields(form)">
                  <label for="scopusId" class="inputLbl">Enter your SCOPUSID</label>
                  <warning fid="scopusId"></warning>
               </div>

     
               <br>
               <!---------- CITIZENSHIP ---------->
               <table class="citizenship" >
                  <tr>
                     <td>
                        <h2 id="auCit">Citizenship</h2>
                        <br>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <p class="usCitizenship">U.S. Citizen:
                           <span id="usCitizen" [textContent]="!form.usCitizen ? 'No' : 'Yes'"></span>
                        </p>
                        <div *ngIf="!form.usCitizen">
                           <p>Foreign National Access expiration date:
                              <span [textContent]=convertUTCtoCDT(flags.expirationDate593)></span>
                           </p>
                           <p class="renew-info">To renew your ALCF foreign national access, you will need to re-enter
                              your foreign national information in the ALCF Foreign National Access Request form.
                           </p>
                        </div>
                        <div *ngIf="flags.newFavorDataNeeded ==='true' && !flags.favorComplete">
                           <div>
                              <button 
                                  (click)="flags.preFavorNotice=true; goToTopOfCitizenArea('react')" 
                                  [disabled]="(!flags.allReqdFieldsComplete) || (flags.fldHasError)"
                                  class="btn btn-primary btn-lg" 
                                  id="enterForeignNationalInfo">
                              Enter Foreign National Info
                              </button>
                              <span *ngIf="!flags.allReqdFieldsComplete" id="pearf">
                                 <font color=red>
                                    Please enter all required fields (<b>in red</b>) before pressing this button
                                 </font>
                              </span>
                           </div>
                           <div *ngIf="flags.preFavorNotice" class="round-corner prefavor-notice">
                              <docs-needed></docs-needed>
                              <br><br>
                              Please gather all these documents before you click the Visit button below.
                              <br>
                              <button  (click)="tryToGoToFavor()" 
                                       class="btn btn-primary btn-lg" 
                                       [disabled]="(!flags.allReqdFieldsComplete) || (flags.fldHasError)"
                                       id="visitRequestForm">
                              Visit Access Request Form
                              </button>

                              <span *ngIf="flags.preFavorReq">
                                 <br>
                                 <font color=red>{{flags.preFavorReq}}</font>
                              </span>
                              <button (click)="saveAndReturnLater()"
                                 class="btn btn-primary btn-lg" id="saveAndReturnLater"
                                 [disabled]="!flags.allReqdFieldsComplete">
                              Save And Return Later
                              </button>
                           </div>
                        </div>
                        <span *ngIf="flags.loading">
                                 <br><font color=green><b>Loading Foreign National Entry Form. Please wait ...</b></font>
                                 <img src="/frontend/loading.gif">
                        </span>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <p class="renew-info usCitizenship">Please contact <a href="mailto:{{domainGlobals.helpDeskEmail}}"
                           [textContent]="domainGlobals.helpDeskEmail"></a> if your
                           citizenship status has changed.
                        </p>
                     </td>
                  </tr>
               </table>
               <!---------- TERMS OF USE ---------->
               <div class="policyAgreement" >
                  <h2>Terms of Use</h2>
                  <div class="acc-upd-p">
                     <policy-agreement-option [form]="form" [onCheckBoxChange]="valAllAcUpdFieldsH"></policy-agreement-option>
                  </div>
                  <div class="acc-upd-p">
                    <div class="up-acc cBox primaryCheckBox" required>
                       <input type="checkbox" id="verify" [(ngModel)]="confirmSaveChecked" (change)="verifyAccount()">
                       <span>I verify that my account details are correct</span>
                    </div>
                  </div>
               </div>
               <div class="updated-message" *ngIf="alerts.length > 0">
      
                  <div uib-alert *ngFor="let alert of alerts"
                    [ngClass]="'alert-' + (alert.type || 'warning')"
                     close="closeAlert(i)" dismiss-on-timeout="99999">{{alert.msg}}
                  </div>
               </div>
     
               <!-- SUBMIT SAVE BUTTONS -->
               <div class="updateAcountBtn">

                  <input 
                     type="submit"
                     id="subReactReq"
                     [disabled]="flags.disableReactivation || !confirmBtn  || disableReactivateSubmit"
                     class="btn btn-success btn-lg" 
                     value="SUBMIT REACTIVATION REQUEST" 
                     (click)="submitReactivate()">
                  <span class="cancel-reactivation-request">
                     <a id="cancelRequest"
                        (click)="cancelAccountReactivation()">
                           <u>Cancel Reactivation Request</u>
                     </a>
                  </span>
               </div>
               <div class="alert alert-danger" *ngIf="lists.errorEditRecord">{{lists.errorEditRecord}}</div>
            </div>
         </div>
      </div>
    </div>

    <ng-container *ngIf="flags.reactivateSubmitted" >
      <div class="reversed-white"><h1>Account {{flags.needNewAccount === true?'Request':'Reactivation'}} Submitted</h1></div>
      <div class="round-corner request-complete">
          <strong>Thank you for your request to reactivate your account.</strong>
          <br><br>
          <span>
             Your ALCF account reactivation has been received. We will notify you 
             once we've obtained approval from the project PI and process your application.
          </span>
          <br><br>
          If you have any questions or concerns, please contact the accounts team at  
          <a href="mailto:accounts@alcf.anl.gov"> accounts&#64;alcf.anl.gov. </a>
      
          <br><br>
          Thanks!
          <div class="sign-out">
              <button class="btn btn-success btn-lg" id="btn"
                      (click)="signOut()">
                  HOME
              </button>
          </div>
      </div>
      <br>
    </ng-container>

    <div>
       <div class="alert alert-warning" *ngIf="flags.err1 && flags.reactivateSubmitted">
          <a href="" (click)="flags.error = !flags.error" class="close">&times;</a>
          <strong ng-bind="flags.error"></strong>
          <div>Please email <span ng-bind="lists.domainGlobals.helpDeskEmail"></span> 
            or call <span ng-bind="lists.domainGlobals.helpDeskTitle"></span> at 
            <span ng-bind="lists.domainGlobals.helpDeskPhoneString"></span></div>
       </div>
    </div>
  </span>
 </div>
 