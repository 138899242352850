export class GlobalConstants {

public static ALCFWebsiteURL            : string = "https://www.alcf.anl.gov";
public static ALCFPolicyAgreementURL    : string = "https://docs.alcf.anl.gov/policies/facility-policies/";
public static DDAllocationDataPolicyURL : string = "https://docs.alcf.anl.gov/policies/data-and-software-policies/data-policy/";
public static ALCFNewsURL               : string = "https://cors-anywhere.herokuapp.com/http://www.alcf.anl.gov/news/rss.xml"; // not used
public static GetStartedURL             : string = "https://www.alcf.anl.gov/support-center/get-started";
// public static ThetaURL                  : string = "https://docs.alcf.anl.gov/theta/hardware-overview/machine-overview/";
// public static CooleyURL                 : string = "https://docs.alcf.anl.gov/cooley/cooley-overview/"; // same for Cooley-OverviewURL
public static MachineStatusURL          : string = "https://www.alcf.anl.gov/support-center/machine-status";
public static ALCFPolicyAckURL          : string = "https://docs.alcf.anl.gov/policies/alcf-acknowledgement-policy/";
public static OverviewURL               : string = "https://www.alcf.anl.gov/about";
public static StaffDirectoryURL         : string = "https://www.alcf.anl.gov/about/people";
public static CareersURL                : string = "https://www.alcf.anl.gov/about/careers";
public static VisitUsURL                : string = "https://www.alcf.anl.gov/about/visiting-alcf";
public static AllocationRequestURL      : string = "https://www.alcf.anl.gov/support-center/get-started/request-allocation";
public static DDAllocationURL           : string = "https://www.alcf.anl.gov/science/directors-discretionary-allocation-program";
public static SupportCenterURL          : string = "https://www.alcf.anl.gov/support-center";
public static ALCFResourcesURL          : string = "https://www.alcf.anl.gov/alcf-resources";
public static ALCFPasscodeTokensURL     : string = "https://docs.alcf.anl.gov/account-project-management/accounts-and-access/alcf-passcode-tokens/"; // same for crypto link
public static TrainingEventsURL         : string = "https://www.alcf.anl.gov/events";

// if there's a change for the following four URLs, please change the same in ddAllocResources.json file as well.
// public static ThetaOverviewURL          : string = "https://docs.alcf.anl.gov/theta/hardware-overview/machine-overview/";
// public static ThetaGPUOverviewURL       : string = "https://docs.alcf.anl.gov/theta-gpu/hardware-overview/theta-gpu-machine-overview/";
// public static GrandURL                  : string = "https://www.alcf.anl.gov/alcf-resources/storage-and-networking#grand";
public static EagleURL                  : string = "https://www.alcf.anl.gov/alcf-resources/storage-and-networking#eagle";
public static TrainingURL               : string = "https://www.alcf.anl.gov/events";
public static DocumentationURL          : string = "https://docs.alcf.anl.gov/";

}