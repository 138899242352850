import { Component, OnInit }  from '@angular/core';
import { Observable } from 'rxjs';
import {CommonService} from '@common/services';
import {Router} from '@angular/router';
import {accountRequestModule} from '../../../../../../../../frontend/accountRequestModule';
import {validationModule}     from '../../../../../../../../frontend/validationModule';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GlobalConstants } from '@common/constants/global-constants';
import { SweetAlert } from '../../../shared/services/sweetalert.service';


var scope = null;
var t = null;
var clog = null;
var agreementParams = null;

@Component({
  selector   : 'account-request',
  templateUrl: './account-request.component.html',
  styleUrls  : ['./account-request.component.css']
})
export class AccountRequestComponent implements OnInit {

  flags = {
    missing:null,
    okToSubmit: null,
    level: 1,
    needNewAccount: true,
    accountRequestDone: false,
    noAffErr: true
  };
  user = {role: 'public'};
  fieldErrors = null;
  lists = [];
  form = {};
  state = null;
  publicPage(b) { };
  domainGlobals: any = {};
  ALCFPasscodeTokensURL = GlobalConstants.ALCFPasscodeTokensURL;
  gte16 = false;
  gte16no = false;

  constructor(
    private commonService: CommonService,
    private router: Router,
    public sweetAlert: SweetAlert
  ) { 
    scope = t = this;
    clog = t.commonService.ub3consoleLog;
    t.fieldErrors = t.commonService.getSharedObject('warning', 'fieldErrors');  
    t.state       = t.commonService.getSharedObject('app', 'state');
    agreementParams = t.commonService.getSharedObject('agreement', 'params');
  }

  ngOnInit(): void {
    
    if (t.state && t.state.user && (t.state.user.role == 'limited') &&
                                   (t.state.user.limited_type == 'REACTIVATE')){
        window.location.hash = '/';
        return;
    }
    agreementParams.pageName = 'Request an Account';
    accountRequestModule.overWriteScope(t);
    validationModule.overWriteScope(t);
    t.user = t.state.user;
    if (t.user.signedIn && (t.user.role == 'limited')){
        t.gte16 = true;
    }
    t.initAccountRequestForm(t.state.backFromFavor);
    document.body.classList.add('public-page');
    t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
    t.flags.accountRequestDone = false;
    t.commonService.doWhenAPICallsDone(function(){
      t.validateNow();
    });
  }

  initAccountRequestForm(backFromFavor){
    scope.flags['displayedPageName'] = 'Request an Account';
    accountRequestModule.init(backFromFavor);
    t.form.cryptoKeyOption = 'Mobile';  // default
  };

  afterLimitedLogin(LLF){
      t.form.email = LLF.email;
      t.form.altEmail = LLF.altEmail;
      t.form.firstName = LLF.firstName;
      t.form.lastName = LLF.lastName;
      t.form.middleName = LLF.middleName;
      t.form.nmi = LLF.nmi;
      t.form.preferredName = LLF.preferredName;
      t.form.userName = LLF.userName;
      accountRequestModule.getLists(t.lists);
      accountRequestModule.getForm();
      t.commonService.doWhenAPICallsDone(function(){
        t.validateNow();
      });
  }
  afterPjSelect(selectedPjsArray){
      t.flags.level = (selectedPjsArray.length > 0) ? 7 : 6;
      t.validateNow();
  }
  setGTE16(){
    t.gte16 = true;
  }
  setGTE16no(){
    t.gte16no = true;
  }
  not16goBackNow(){
    window.location.hash = "/";
  }

  saveForLater() {
        accountRequestModule.saveForLater(t.form, t.flags, function(){
          t.validateNow();
        });
  };    
  addAffiliation(){
    accountRequestModule.addAffiliation(t.form);
  }

  deleteAffiliation(i){
    if (!scope.form.affiliationList){
        return;
    }
    if (scope.form.affiliationList.length > i){
        scope.form.affiliationList.splice(i,1);
    }
  }

  validateCountries(){
        if (!good(t.form.phoneCountry))    t.form.phoneCountry = '';
        if (!good(t.form.workCountry))     t.form.workCountry = '';
        if (!good(t.form.shippingCountry)) t.form.shippingCountry = '';

        function good(s){
            var all, i, row;
            all = t.lists.countries;
            for (i in all){
                row = all[i];
                if (row.name == s){
                    return true;
                }
            }
            return false;
        }
  }

  searchCountry = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.countries.filter(function(c){
          if(/\d/.test(term)){
            return c.dialCode === term;
          } else {
            return (c.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
          }
        }).map(function (c) {
          return c.name;
        });
      })
  )

  onlyOneAff(i){
    accountRequestModule.onlyOnePrimary(i, scope.form.affiliationList);
  }

  // this function works for any input field.  It figures out which field it is by calling .target
  public onFocus(e: Event, currVal): void {
    if (currVal && (currVal != '')) return;

    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

    // specific to the states in USA
    // fieldName will be:  'workState' or 'shippingState'
    onStateChange(prefix){
        var fieldName = prefix + 'State';
        if (t.form[prefix + 'Country'] != 'United States') return;

        //t.commonService.onStopCalling(700, getStAbr);
        getStAbr();
  
        function getStAbr(){
          var val = t.form[fieldName];
          var ss = t.lists.usaStates.filter(function(st){
            return (st.name === val) || (st.abr  === val);
          });
          t.form[fieldName] = (ss[0] ? ss[0].abr : '');
        }
  
    }
    //ngbTypeahead for state abbreviation
    searchState = (text$: Observable<string>) =>
      text$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          map(function(term){
            var sl = term.toLowerCase();
            return t.lists.usaStates.filter(function(c){
              return ((c.abr.toLowerCase().indexOf(sl) >= 0) || (c.name.toLowerCase().indexOf(sl) >= 0));
            }).map(function (c) {
              return (c.abr, c.name);
            });
          })
    )

  searchInst = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.institutions.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(inst) {
            return (inst.id, inst.name);
          });
        })
      );
  }

  blurPrefName(){
    accountRequestModule.blurPrefName(scope.form);
    t.validateNow();
  }

  policyAgreementChanged(form){
    accountRequestModule.validateNow(scope.form, scope.flags);
    t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors); // copy from left --> right
    });
  }


  validateDelayed(fieldName) {
    t.commonService.onStopCalling(700, function(){
        t.validateNow(fieldName);
    });
  }

  validateNow(fieldName) {
        t.validateCountries();
        t.flags.phoneDialCode = t.commonService.getCountryCode(t.form.phoneCountry, scope.lists);
        t.onStateChange('work');
        t.onStateChange('shipping');
        accountRequestModule.validateNow(scope.form, t.flags, fieldName);
        t.commonService.doWhenAPICallsDone(function(){
            t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors); // copy from left --> right
            t.flags.noAffErr = !(t.fieldErrors && t.fieldErrors.affiliationList);
        });
  }

  goToTopOfCitizenArea(whichPage){
        var elem = document.getElementById('arCit');
        elem.scrollIntoView();
  };

  goToFAVOR() {
    accountRequestModule.goToFAVOR(scope.form, scope.flags, t.domainGlobals.favorRedirectPath, null);
  } 

  arqLocateByZip(fieldPrefix) {
    accountRequestModule.locateByZip(scope.form, fieldPrefix);
  };

  submit() {

    accountRequestModule.saveForLater(scope.form, scope.flags, function(){
        accountRequestModule.submit(scope.form, scope.flags);
    });
  };


  signOut(delay){
    t.commonService.signOut(delay, t, false, t.router);
  }
  signOutNowStay(){
    t.commonService.signOut(null, t, true, t.router);
  }
  cancelAccountRequest(){
    accountRequestModule.delSaveForLater(function(){
        t.commonService.deleteContext('Request an Account');
        t.commonService.signOut(0, t, false, t.router);
    });
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(t.state.user && t.state.user.signedIn && (t.state.user.role === 'limited')){
      return t.sweetAlert.confirmSwitchRequests();
    } else {
      return true;
    }
  }

}

