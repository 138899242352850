

<div class="card ub3-card" *ngIf="!initDone">
    <div class="card-body">
        Loading data ...
    </div>
</div>
<div class="card ub3-card" *ngIf="initDone" id="limLogin">
    <div class="card-body">
        <div class="info">Enter your information below to receive an access code via email.</div>
        <div *ngIf="flags.needNewAccount || flags.reactivateAccount || flags.allocReq" class="form-group floating-label-wrapper">
            <input
                type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.email"
                id="email"
                placeholder=" "
                (keyup)="validateLL(LLF)"
                [disabled]="stage > 1">
            <label for="email" class="inputLbl" *ngIf="flags.needNewAccount || flags.allocReq">Email Address</label>
            <label for="email" class="inputLbl" *ngIf="flags.reactivateAccount">Email address associated with your account</label>
            <warning fid="email"></warning>
            <div *ngIf="flags.reactivateAccount && !flags.isReactivateSurvey" class="changedEmail">
                <div><font color=red>Changed your email?</font></div>
                <div>Contact <a href="mailto:{{domainGlobals.helpDeskEmail}}"
                    [textContent]="domainGlobals.helpDeskEmail"></a> to update your account.
                </div>
            </div>
        </div>
        <div class="form-group floating-label-wrapper" *ngIf="flags.moreLL">
            <input
                type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.altEmail"
                id="altEmail"
                placeholder=" "
                (keyup)="validateLL(LLF)">
            <label for="altEmail" class="inputLbl">Alternative Email (optional)</label>
            <warning fid="altEmail"></warning>
        </div>
        <div class="form-group floating-label-wrapper">
            <input type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.firstName"
                id="firstName"
                placeholder=" "
                (keyup)="validateLL(LLF)"
                [disabled]="stage > 1">
            <label for="firstName" class="inputLbl">Legal First/Given Name</label>
            <warning fid="firstName"></warning>
        </div>
        <div class="form-group floating-label-wrapper" *ngIf="flags.moreLL">
            <input
                type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.middleName"
                id="middleName"
                placeholder=" "
                (ngModelChange)="validateLL(LLF)"
                [disabled]="LLF.nmi">
            <label for="middleName" class="inputLbl">Middle Name</label>
            <span class="cBox">
                <input type="checkbox"
                       id="nmi"
                       (ngModelChange)="validateLL(LLF, 'nmi')"
                       [(ngModel)]="LLF.nmi">
                <span>No Middle Name</span>
            </span>
            <warning fid="middleName"></warning>
        </div>
        <div class="form-group floating-label-wrapper">
            <input
                type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.lastName"
                id="lastName"
                placeholder=" "
                (keyup)="validateLL(LLF)"
                [disabled]="stage > 1">
            <label for="lastName" class="inputLbl">Legal Last/Family Name</label>
            <warning fid="lastName"></warning>
        </div>
        <div class="form-group floating-label-wrapper" *ngIf="flags.moreLL">
            <input
                type="text"
                class="form-control inBox"
                [(ngModel)]="LLF.preferredName"
                id="preferredName"
                placeholder=" "
                (change)="validateLL(LLF)">
            <label for="preferredName" class="inputLbl">Preferred Name</label>
            <warning fid="preferredName"></warning>
        </div>

    <ng-container *ngIf="stage <= 1">

        <button class="btn btn-primary" ng-class="{'btn-md': !mobile,'btn-lg':mobile}"
            id="emailAccessCode"
            [disabled]="!flags.okToSubmit || flags.emailCodeVerified"
            (click)="flags.okToSubmit=false; doLimitedLogin(LLF)">
          EMAIL ACCESS CODE
       </button>
    <div *ngIf="flags.extraMessage" 
         id="exmsg"
         class="alert alert-danger">
        <b>{{flags.extraMessage}}</b>
    </div>
    </ng-container>

    <div class="alert alert-danger" *ngIf="flags.err1 && !flags.reactivateAccount && (!flags.extraMessage)">
            <a href="" (click)="flags.err1 = !flags.err1" class="close">&times;</a>
            <strong id="error">{{flags.err1}}</strong>
            <div *ngIf="flags.redirectToReactivate"><a href="accountReactivate">Reactivate an Account</a></div>

    </div>
    <div class="alert alert-danger" *ngIf="flags.err1 && flags.reactivateAccount && (!flags.extraMessage)">
        <a href="" (click)="flags.err1 = !flags.err1" class="close" data-dismiss="alert">&times;</a>
        <strong id="error">{{flags.err1}}</strong>
        <div>
            Please email <span [textContent]="lists.domainGlobals.helpDeskEmail"></span> 
            or call <span [textContent]="lists.domainGlobals.helpDeskTitle"></span> at 
            <span [textContent]="lists.domainGlobals.helpDeskPhoneString"></span>
        </div>
    </div>

    <div *ngIf="stage == 2" id="enter_access_code">

            <h3>Enter Access Code</h3>
            An access code was sent to 
            <font color="#009900"><b>{{LLF.email}}</b></font>
            on {{flags.emailTimeSent | date: "medium"}}.<br>
            Check your inbox for the access code and place the code below.<br>
            Didn't receive an email from us? Check your spam folder and make sure the<br>
            email entered above is correct. If you need help, email {{domainGlobals.helpDeskEmail}}.

        <div class="form-group floating-label-wrapper">
            <input  type="password"
                    class="form-control inBox"
                    id="accessCode"
                    [(ngModel)]="flags.accessCode"
                    (keyup)="verifyEmailCode(flags.accessCode)"
                    [disabled]="flags.emailCodeVerified"
                    placeholder=" ">
            <label for="accessCode" class="inputLbl">Enter the access code here</label>
        </div>

    </div>

    <div *ngIf="flags.askForCodeError && (!flags.extraMessage)" 
         id="code-error"
         class="alert alert-danger">
        {{flags.askForCodeError}} 
    </div>
    <span *ngIf="flags.confirmingCode">
                 <br>Confirming code ... please wait
    </span>

    <span *ngIf="stage >= 3">

        <!-- User needs to create a security Question and Answer -->
        <span *ngIf="stage == 4">    

            <h3>Account Security Question</h3>
            <span class="accSecQLbl">You will need to know this question and answer for future security
            reasons, so it is important to remember what you enter.</span>
            <ul class="accSecQInstrns">
                <li>Use a question and answer that is easy for you to answer and hard
                    for others to guess.</li>
                <li>You may be asked this question to confirm your identity when
                    speaking to user support</li>
                <li>Do not share this question and answer.</li>
            </ul>
            <div class="form-group floating-label-wrapper">
                <input
                    type="text"
                    class="form-control inBox"
                    [(ngModel)]="LLF.securityQuestion"
                    id="securityQuestion"
                    placeholder=" "
                    (change)="validateLL(LLF)">
                <label for="securityQuestion" class="inputLbl">Security Question</label>
                <warning fid="securityQuestion"></warning>
            </div>
            <div class="form-group floating-label-wrapper" ng-class="{'error-section': hasError('securityAnswer')}">
                <input
                    type="{{flags.showSA ? 'text' : 'password'}}"
                    class="form-control inBox"
                    [(ngModel)]="LLF.securityAnswer"
                    id="securityAnswer"
                    placeholder=" "
                    (keyup)="validateLL(LLF)">
                <label for="securityAnswer" class="inputLbl">Security Answer</label>
                <img *ngIf="flags.showSA"  (click)="flags.showSA=false" 
                 uib-tooltip="hide security answer"
                 tooltip-placement="top" 
                 height=20 border=0 src="/frontend/assets/img/eyeClose.png">
                <img *ngIf="!flags.showSA" (click)="flags.showSA=true"  
                 uib-tooltip="show security answer"
                 tooltip-placement="top" 
                 height=20 border=0 src="/frontend/assets/img/eyeOpen.png">

                <warning fid="securityAnswer"></warning>
            </div>
            <div class="form-group floating-label-wrapper" *ngIf="false" ng-class="{'error-section': hasError('confirmSecurityAnswer')}">
                <input
                    type="password"
                    class="form-control inBox"
                    [(ngModel)]="LLF.confirmSecurityAnswer"
                    id="confirmSecurityAnswer"
                    placeholder=" "
                    (keyup)="validateLL(LLF)">
                <label for="confirmSecurityAnswer" class="inputLbl">Security Answer (confirm)</label>
                <warning fid="confirmSecurityAnswer"></warning>
            </div>
            <button
                class="btn btn-primary btn-md"
                [disabled]="!flags.okToSaveQA"
                (click)="createQA(LLF)" id="continue">
                CONTINUE
            </button>
        </span>

        <!-- User is challenged to answer the security Question -->
        <span *ngIf="stage == 5">

            <h2>Account Security Question</h2>
            <div class="form-group floating-label-wrapper" id="securityQuestionForAnswer">
              Security Question: <font color="#00aa00"><b>{{user.securityQuestion}}</b></font>
            </div>
            <div class="form-group floating-label-wrapper" ng-class="{'error-section': hasError('securityAnswer')}">
                <input
                    type="{{flags.showSA ? 'text' : 'password'}}"
                    class="form-control inBox"
                    [(ngModel)]="LLF.securityAnswer"
                    id="securityAnswer"
                    placeholder=" "
                    (keyup)="validateLL(LLF)">
                <label for="securityAnswer" class="inputLbl">Security Answer</label>
                <img *ngIf="flags.showSA"  (click)="flags.showSA=false" 
                 uib-tooltip="hide security answer"
                 tooltip-placement="top" 
                 height=20 border=0 src="/frontend/assets/img/eyeClose.png">
                <img *ngIf="!flags.showSA" (click)="flags.showSA=true"  
                 uib-tooltip="show security answer"
                 tooltip-placement="top" 
                 height=20 border=0 src="/frontend/assets/img/eyeOpen.png">

                <warning fid="securityAnswer"></warning>
            </div>
    
            <button
                [disabled]="!flags.okToSaveQA"
                class="btn btn-primary btn-md"
                (click)="verifySecurityAnswer(LLF.securityAnswer,$event)"
                id="continue">
                CONTINUE
            </button>
            <div *ngIf="flags.wrongAnswer"><font color=red>{{flags.wrongAnswer}}</font></div>
        </span>
    </span>

    <div class="cancelReq">
        <a *ngIf="flags.accountReactivate" (click)="cancelAccountReactivation()" id="cancelAcReact" class="cancelAcc">
            Cancel Account Reactivation
        </a>
        <a *ngIf="flags.allocReq" (click)="cancelAllocRequest()" id="cancelAllocReq" class="cancelAcc">
            Cancel Request
        </a>
        <a *ngIf="flags.needNewAccount" (click)="cancelAccountRequest()" id="cancelAcReq" class="cancelAcc">
            Cancel Account Request
        </a>
    </div>

    <!-- what does this div do ??????   -->
    <div class="alert alert-danger" *ngIf="flags.Aerror && !flags.isReactivateSurvey">
                <a href="" (click)="flags.Aerror = !flags.Aerror" class="close">&times;</a>
                <strong [textContent]="flags.Aerror" id="bottomErrorMessage"></strong>

    </div>
    <div *ngIf="flags.readError" class="alert alert-danger">
        {{flags.readError}}
    </div>
    <span *ngIf="false">
        LLF  : <json-formatter json="LLF"   open="0"></json-formatter>
        user : <json-formatter json="user"  open="0"></json-formatter>
        flags: <json-formatter json="flags" open="0"></json-formatter>
        lists: <json-formatter json="lists" open="0"></json-formatter>
        domainGlobals: <json-formatter json="domainGlobals" open="0"></json-formatter>
    </span>
    </div>
</div>
