import { NgModule }              from '@angular/core';
import { BrowserModule }         from '@angular/platform-browser';
import { ReactiveFormsModule }   from '@angular/forms';
import { FormsModule }           from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent }          from './app.component';
import { appRoutingModule, routingComponents } from './app-routing.module';
import { AlertComponent }        from './views/partials/alert';
//import { ErrorInterceptor }      from './_helpers';
import { CookieService }         from 'ngx-cookie-service';
import { NgbModule }             from '@ng-bootstrap/ng-bootstrap';
import { NgxJsonViewerModule }   from 'ngx-json-viewer';
import { NgxDatatableModule } from '@siemens/ngx-datatable';

import { AccountRequestComponent} from './views/pages/account-request/account-request.component';
import { AccountUpdateComponent } from './views/pages/account-update/account-update.component';
import { AllocationRequestComponent } from './views/pages/allocation-request/allocation-request.component';
import { CreateOtherAccountsComponent } from './views/pages/create-other-accounts/create-other-accounts.component';
import { InstitutionsComponent } from './views/pages/institutions/institutions.component';
import { LogsComponent }         from './views/pages/logs/logs.component';
import { PluginsComponent }      from './views/pages/plugins/plugins.component';
import { SvcApiComponent }       from './views/pages/svc-api/svc-api.component';
import { UserAdminComponent }    from './views/pages/user-admin/user-admin.component' ;

import { AccountEditComponent }  from './shared/account-edit/account-edit.component' ;
import { MkidPipe }              from './shared/mkid.pipe';
import { MultiFilterComponent }  from './shared/multi-filter/multi-filter.component' ;
import { PolicyAgreementOptionComponent } from './shared/policy-agreement-option/policy-agreement-option.component';
import { SingleFilterComponent } from './shared/single-filter/single-filter.component' ;
import { ThInstitutionSortComponent } from './shared/th-institution-sort/th-institution-sort.component';
import { ThSvcSortComponent }    from './shared/th-svc-sort/th-svc-sort.component';
import { Ub3dateComponent }      from './shared/ub3date/ub3date.component';
import { WarningAComponent }     from './shared/warning-a/warning-a.component';
import { WarningComponent }      from './shared/warning/warning.component';
import { WarningSvcComponent }   from './shared/warning-svc/warning-svc.component';
import { AlertMsgComponent }     from './shared/alerts/alert-msg.component';
import { FooterComponent }       from './shared/footer/footer.component';
import { DocsNeededComponent }   from './shared/docs-needed/docs-needed.component';
import { AgreementAnlComponent } from './shared/agreement-anl/agreement-anl.component';
import { AgreementAlcfComponent } from './shared/agreement-alcf/agreement-alcf.component';
import { AccountReactivateComponent } from './views/pages/account-reactivate/account-reactivate.component';
import { CreateOtherAccountsFormComponent } from './views/pages/create-other-accounts-form/create-other-accounts-form.component';
import { LimitedLoginComponent } from './shared/limited-login/limited-login.component';
import { SelectProjectsComponent } from './shared/select-projects/select-projects.component';
import { DataSurveyComponent } from './views/pages/data-survey/data-survey.component';
import { DataCollectionComponent } from './shared/data-collection/data-collection.component';
import { LoginComponent } from './shared/login/login.component';
import { ProjectAdminComponent } from './views/pages/project-admin/project-admin.component';
import { ProjectEditComponent } from './views/pages/project-edit/project-edit.component';
import { ProjectManagementComponent } from './views/pages/project-management/project-management.component';
import { ProjectManagementEditComponent } from './views/pages/project-management-edit/project-management-edit.component';
import { SystemRequestComponent } from './views/pages/system-request/system-request.component';
import { ManageUnixGroupsComponent } from './views/pages/manage-unix-groups/manage-unix-groups.component';
import { JoinProjectComponent } from './views/pages/join-project/join-project.component';
import { TimeoutModalComponent } from './shared/timeout-modal/timeout-modal.component';
import { UserEditComponent } from './views/pages/user-edit/user-edit.component';
import { ApiDevIntComponent } from './views/pages/api-dev-int/api-dev-int.component';
import { Ub3thComponent } from './shared/ub3th/ub3th.component';
import { Ub3tblComponent } from './shared/ub3tbl/ub3tbl.component';
import { AclTagsComponent } from './views/pages/acl-tags/acl-tags.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { SweetAlert } from './shared/services/sweetalert.service';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        appRoutingModule,
        NgbModule,
        NgxJsonViewerModule,
        NgJsonEditorModule,
        NgxDatatableModule
    ],
    declarations:[
        AccountEditComponent,
        AccountRequestComponent,
        AccountUpdateComponent,
        AlertComponent,
        AllocationRequestComponent,
        AppComponent,
        CreateOtherAccountsComponent,
        InstitutionsComponent,
        LogsComponent,
        MkidPipe,
        MultiFilterComponent,
        PluginsComponent,
        PolicyAgreementOptionComponent,
        routingComponents,
        SingleFilterComponent,
        SvcApiComponent ,
        ThInstitutionSortComponent,
        ThSvcSortComponent ,
        Ub3dateComponent ,
        UserAdminComponent,
        UserEditComponent,
        WarningAComponent ,
        WarningComponent ,
        WarningSvcComponent,
        AlertMsgComponent,
        FooterComponent,
        DocsNeededComponent ,
        AgreementAnlComponent ,
        AgreementAlcfComponent ,
        AccountReactivateComponent ,
        CreateOtherAccountsFormComponent ,
        SelectProjectsComponent,
        LimitedLoginComponent,
        DataSurveyComponent,
        DataCollectionComponent,
        LoginComponent,
        ProjectAdminComponent,
        ProjectEditComponent,
        SystemRequestComponent,
        ProjectManagementComponent,
        ProjectManagementEditComponent,
        ManageUnixGroupsComponent,
        JoinProjectComponent,
        TimeoutModalComponent,
        ApiDevIntComponent,
        Ub3thComponent,
        Ub3tblComponent,
        AclTagsComponent
    ],
    providers: [CookieService, SweetAlert,
        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
       // {provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true},
      // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
       {provide: Window, useValue: window}
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
